import React, {Component} from "react";
import ProductCard from "../../components/Product/ProductCard";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";
import $ from "jquery";

export default class ProductListPage extends Component {
    state = {
        products: [],
        categories: [],
        pagination: {},
        search: '',
        cat_id: ''
    }

    async componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        let category = urlParams.get('category');
        let page = urlParams.get('page') || 1;
        await this.getProducts(page, category);
        await this.getCategories();

    }

    getCategories = async () => {
        let data = [];
        await axios.get(window.base_URL + '/category/list').then(res => {
            data = res.data.result.reverse()
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return {categories: data};
        });
    };
    getProducts = async (page,category) => {
        let URL = window.base_URL + '/product/list' + '?page=' + page;
        if(category) URL = window.base_URL + '/product/category/' + category + '?page='+page;
        let data = [];
        let pagination = {};
        await axios.get(URL)
            .then(res => {
                data = res.data.result
                pagination = res.data.pagination
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return {products: data, pagination: pagination, currentPage: page};
        });
    }

    prevPage() {
        const urlParams = new URLSearchParams(window.location.search);
        let page = urlParams.get('page');
        if (page) {
            page--
        } else {
            page = 1
        }
        window.location.href = "?page=" + page
    }

    nextPage() {
        const urlParams = new URLSearchParams(window.location.search);
        let page = urlParams.get('page');
        if (page) {
            page++
        } else {
            page = 2
        }
        let URL = "?page=" + page
        let category_id = urlParams.get('category_id');
        if (category_id) URL = "?page=" + page + "&category_id=" + category_id;
        window.location.href = URL
    }

    getProductsWithCategory = event => {
        const cat_id = event.target.id;
        const page = 1;
        window.location.href = "?page=" + page + "&category=" + cat_id;
    }
    
    handleChange = event => {
        let search = event.target.value
        this.setState({search});
    };
    searchOnTitle = async (e) => {
        e.preventDefault();
        const URL = window.base_URL + "/product/search?fields=title&q=" + this.state.search
        let data = [];
        let pagination = {};
        await axios.get(URL).then(res => {
            data = res.data.result;
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return {products: data,pagination};
        });
    }

    render() {
        const urlParams = new URLSearchParams(window.location.search);
        let category_id = urlParams.get('category');
        let {products, categories, pagination, search} = this.state
        return (
            <div>
                <BannderHead title={"لیست محصولات"}/>
                <div className="ps-page--shop">
                    <div className="categories" data-sticky="true">
                        <div className="ps-shopping__top__center d-flex">
                            <div className='input-group rounded-pill '>
                                <form onSubmit={this.searchOnTitle}>
                                <label htmlFor="serach" className='searchInputButton'>
                                    <input type="text" className="form-control rounded-pill h-auto"
                                           placeholder="جستجو عنوان محصول"
                                           onChange={this.handleChange}
                                           name='search'
                                           value={search}
                                           id='search'/>
                                    <button className=" rounded-circle " type="submit"
                                            id="button-addon1" style={{zIndex: 1}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                        </svg>
                                    </button>
                                </label>
                                </form>
                                
                            </div>
                        </div>
                        <div className="ps-shopping__top">
                            <div className="ps-shopping__top__left">
                                <ul className="ps-product__categories" dir="rtl">
                                    <li>
                                        <a onClick={() => this.getProducts(1)} style={{'cursor': 'pointer'}} className={`${!category_id ? "primary-color" :""}`}>
                                            همه
                                        </a>
                                    </li>
                                    {categories.map((category, index) => {
                                        return <li key={index}><a onClick={this.getProductsWithCategory}
                                                                  className={`${category._id === category_id ? "primary-color" :""}`}
                                                                  style={{'cursor': 'pointer'}}
                                                                  id={category._id}>{category.title}</a></li>
                                    })}
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="ps-shopping ps-shopping--no-sidebar">

                            <div className="ps-product-box five-column">
                                <div className="row">
                                    {products.map((product, index) => {
                                        return <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 " key={index}>
                                            <ProductCard product={product}/>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="ps-pagination">
                                <ul className="pagination">
                                    {pagination.page != 1 ?
                                        <li><a onClick={this.prevPage}><i className="fa fa-caret-left"></i></a>
                                        </li> : ""}
                                    {Array.from(Array(pagination.pages), (e, item) => {
                                        return <li className={`${pagination.page == (item + 1) ? 'active' : ''}`}
                                                   key={item + 1}><a href={"?page=" + (item + 1) + `${category_id ? "&category=" + category_id : ""}`}>{item + 1}</a></li>
                                    })}
                                    {pagination.page != pagination.pages ?
                                        <li><a onClick={this.nextPage}><i className="fa fa-caret-right"></i></a>
                                        </li> : ''}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
