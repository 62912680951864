import React, {Component} from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";


export default class AdminClientFormPage extends Component {

    state = {
        client:{
            enabled: '',
            name: '',
            address: '',
            phone: '',
            postCode:'',
            shopTitle:'',
            tel:'',
            showOther:''
        },
        status: 0
    }
    async componentDidMount() {
        await this.getClient();
    }
    getClient = async () => {
        let Client = [];

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');

        await axios.get(window.admin_base_URL + '/client/read/' + myParam).then(res => {
            Client = res.data.result
        }).catch(err=>{
            console.error(err)
        })
        this.setState(() => {
            return {client: Client};
        });
    };
    handleChange = event => {
        this.setState({
            client:{
                ...this.state.client,
                [event.target.name]: event.target.value
            }
        });
    };
    createClient = async event => {
        event.preventDefault();
        const {client} = this.state;
        let URL = window.admin_base_URL + "/client/create"
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam){
            URL = window.admin_base_URL + "/client/update/" + myParam;
            
            await axios.patch(URL, client)
                .then(() => {
                    this.setState({
                        client,status:200
                    });
                    window.location.href = "/adminPanel/clients"
                })
                .catch(err => {
                    this.setState({
                        client,status: 400
                    });
                    console.error(err)
                })
        } else{
            await axios.post(URL, client)
                .then(() => {
                    this.setState({
                        client,status: 200
                    });
                    window.location.href = "/adminPanel/clients"
                })
                .catch(err => {
                    this.setState({
                        client,status: 400
                    });
                    console.error(err)
                })
        }

    };

    render() {

        const {
            name,
            address,
            phone,
            postCode,
            shopTitle,
            tel,
            enabled,
            showOther
        } = this.state.client;
        const { status } = this.state;

        return (
            <div id='AdminProductForm'>
                <BannderHead title={"فرم ایجاد/ویرایش مشتری‌ها"}/>
                <div className="">
                    <form className="ps-form--adminPanel" onSubmit={this.createClient}>
                        <div className="row justify-content-center" dir='rtl'>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                                <div className="form-group">

                                    <input
                                        required
                                        className="form-control shadow-sm "
                                        placeholder='نام / نام خانوادگی'
                                        type="text"
                                        name='name'
                                        value={name}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        required
                                        className="form-control shadow-sm "
                                        placeholder='نام فروشگاه'
                                        type="text"
                                        name='shopTitle'
                                        value={shopTitle}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">

                                    <input
                                        className="form-control shadow-sm"
                                        type="text"
                                        placeholder='آدرس'
                                        required
                                        name='address'
                                        value={address}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        required
                                        className="form-control shadow-sm no-spinners"
                                        type="number" placeholder="شماره تماس همراه"
                                        name='phone'
                                        value={phone}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        required
                                        className="form-control shadow-sm no-spinners"
                                        type="number" placeholder="شماره تماس ثابت"
                                        name='tel'
                                        value={tel}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        required
                                        className="form-control shadow-sm no-spinners"
                                        type="number" placeholder="کد پستی"
                                        name='postCode'
                                        value={postCode}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <select className=" form-control shadow-sm" value={enabled} name='enabled'
                                            onChange={this.handleChange}>
                                        <option value='' className='d-none'>وضعیت مشتری</option>
                                        <option value={true}>تایید شده</option>
                                        <option value={false}>تایید نشده</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select className=" form-control shadow-sm" value={showOther} name='showOther'
                                            onChange={this.handleChange}>
                                        <option value='' className='d-none'>نمایش در مشتریان خاص</option>
                                        <option value={true}>نمایش داده شود</option>
                                        <option value={false}>نمایش داده نشود</option>
                                    </select>
                                </div>
                                {status === 200 ?
                                    <div className="alert alert-success text-right" role="alert">
                                        درخواست با موفقیت ثبت شد
                                    </div> : ''
                                }
                                {status === 400 ?
                                    <div className="alert alert-danger text-right" role="alert">
                                        خطا در ثبت اطلاعات رخ داده است.
                                    </div> : ''
                                }
                                <div className="form-group ">
                                    <button className="ps-btn  ps-btn--sm mr-auto " type="submit">ثبت
                                        مشخصات
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        );
    }
}
