import React, { Component } from "react";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";

function commentCard(comment) {

    return <div className="ps-block--comment" dir='rtl'>
        <div className="ps-block__thumbnail ml-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor"
                className="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
            </svg>
        </div>
        <div className="ps-block__content text-right">
            <div className="ps-block__header">
                <h4>{comment.clientName}
                    <div className='d-inline-block mr-3'>
                        <span className={`fa fa-star  pl-1  ${comment.rate >= 1 ? 'gold' : ''}`}></span>
                        <span className={`fa fa-star  pl-1  ${comment.rate >= 2 ? 'gold' : ''}`}></span>
                        <span className={`fa fa-star  pl-1  ${comment.rate >= 3 ? 'gold' : ''}`}></span>
                        <span className={`fa fa-star  pl-1  ${comment.rate >= 4 ? 'gold' : ''}`}></span>
                        <span className={`fa fa-star  pl-1  ${comment.rate >= 5 ? 'gold' : ''}`}></span>
                    </div>
                </h4>
            </div>
            <p>{comment.message}</p>
        </div>

    </div>
}

export default class BlogDetailsPage extends Component {

    state = {
        comments: [],
        comment: {
            rate: '',
            message: '',
            clientName: '',
            clientPhone: '',
            img: '',
            blog: '',
        },
        blog: {},
        status: 0
    }

    async componentDidMount() {
        await this.getBlog();
        await this.getComments()
    }

    handleChange = event => {
        this.setState({
            comment: {
                ...this.state.comment,
                [event.target.name]: event.target.value
            }
        });
    };
    createComment = async event => {
        event.preventDefault();
        this.state.comment.blog = this.props.match.params.id;
        const { comment } = this.state;
        let URL = window.base_URL + "/comment/create"
        await axios.post(URL, comment)
            .then(async () => {
                await this.setState({
                    comment, status: 200
                });
                window.location.reload();
            })
            .catch(async err => {
                await this.setState({
                    comment, status: 400
                });
                console.error(err)
            })


    };
    getComments = async () => {

        let URL = window.base_URL + "/comment/blog/" + this.props.match.params.id
        await axios.get(URL)
            .then(async res => {
                const data = res.data.result
                await this.setState({
                    comments: data,
                });
            })
            .catch(async err => {
                console.error(err)

            })
    };
    getBlog = async () => {
        const { id } = this.props.match.params;
        let data = null;
        await axios.get(window.base_URL + '/blog/read/' + id)
            .then(res => {
                data = res.data.result;
                this.setState({ blog: { ...data, tags: data.tags.split(/[,.٬،]/) } });
            }).catch(err => {
                console.error(err)
            })
        await axios.put(window.base_URL + '/blog/read/' + id);
    };

    render() {
        const {

            message,
            clientName,
            clientPhone,
        } = this.state.comment;
        const tags = this.state.blog.tags || [];
        const { status, comments, blog } = this.state
        return (
            <div className="ps-page">
                <BannderHead title={blog.title} />
                <div className="ps-hero--blog"><img src={window.file_URL + blog.img} style={{ 'width': '100%' }} alt={blog.title} /></div>
                <div className="container">
                    <div className="ps-blog ps-blog--sidebar">
                        <div className="ps-blog__left">
                            <div className="ps-post--detail">
                                <div className="ps-post__content">
                                    <p><strong className="large">{blog.shortDescription}</strong></p>
                                    <p dangerouslySetInnerHTML={{ __html: blog.description }}>

                                    </p>
                                </div>
                            </div>
                            <div className='text-right mb-40'>
                                <h4 className='mb-30'>
                                    کلمات کلیدی
                                </h4>
                                <div>
                                    {tags.length > 0 ? tags.map((item, index) => <div key={index} className='d-inline-block mx-2 px-4 py-2 border text-muted rounded-pill'>{item}</div>) : ""}

                                </div>
                            </div>
                            <div className="ps-blog-comments text-right">
                                <h3 className='mr-auto'>کامنت‌ها <span>({comments.length})</span></h3>
                                <div className="ps-comment-box">
                                    {comments.map(comment => {
                                        return commentCard(comment)
                                    })}
                                </div>
                                <form className="ps-form--leave-a-comment" onSubmit={this.createComment}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className='rating-post'>
                                            <div className="rate">
                                                <input type="radio" id="star5" name='rate' value="5"
                                                    onChange={this.handleChange} />
                                                <label htmlFor="star5" title="text">5 stars</label>
                                                <input type="radio" id="star4" name='rate' value="4"
                                                    onChange={this.handleChange} />
                                                <label htmlFor="star4" title="text">4 stars</label>
                                                <input type="radio" id="star3" name='rate' value="3"
                                                    onChange={this.handleChange} />
                                                <label htmlFor="star3" title="text">3 stars</label>
                                                <input type="radio" id="star2" name='rate' value="2"
                                                    onChange={this.handleChange} />
                                                <label htmlFor="star2" title="text">2 stars</label>
                                                <input type="radio" id="star1" name='rate' value="1"
                                                    onChange={this.handleChange} />
                                                <label htmlFor="star1" title="text">1 star</label>
                                            </div>
                                        </div>
                                        <h4>نظر شما چیست ؟</h4>
                                    </div>
                                    <div className="row" dir='rtl'>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                                            <div className="form-group">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="نام"
                                                    name='clientName'
                                                    value={clientName}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                                            <div className="form-group">
                                                <input
                                                    required
                                                    className="form-control no-spinners"
                                                    type="number"
                                                    placeholder="شماره تلفن"
                                                    name='clientPhone'
                                                    value={clientPhone}
                                                    onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                            <div className="form-group">
                                                <textarea
                                                    required
                                                    className="form-control"
                                                    name='message'
                                                    value={message}
                                                    rows="3"
                                                    placeholder="نظرتان را بنویسید ..."
                                                    onChange={this.handleChange}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {status === 200 ?
                                        <div className="alert alert-success text-right" role="alert">
                                            درخواست با موفقیت ثبت شد
                                        </div> : ''
                                    }
                                    {status === 400 ?
                                        <div className="alert alert-danger text-right" role="alert">
                                            خطا در ثبت اطلاعات رخ داده است.
                                        </div> : ''
                                    }
                                    <div className="ps-form__submit">
                                        <button className="ps-btn" type='submit'>ارسال</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
