import React, {Component} from "react";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";
import Pagination from "../../components/Pagination";


export default class AdminCommentsListPage extends Component {
    state = {
        comments:[],
        pagination:{},

    }
    async componentDidMount() {
      await this.getComments();
    }
    getComments = async () => {
        let URL = window.admin_base_URL + '/comment/list'
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        if (page) URL = window.admin_base_URL + '/comment/list?page=' + page
        let pagination = {};
        let data = [];
        await axios.get(URL)
            .then(res => {
                data = res.data.result
                pagination = res.data.pagination

            }).catch(err=>{
                console.error(err)
            })
        this.setState(() => {
            return {comments: data ,pagination: pagination, currentPage: page};
        });
    }
    changeStatus = async event => {
        if (window.confirm("آیا میخواهید وضعیت کامنت را به تایید شده تغییر بدهید؟")) {
            await axios.patch(window.admin_base_URL + '/comment/update/' + event.target.id, {enabled: true})
                .then(res => {
                    alert('تغییر وضعیت انجام شد')
                    this.getComments();
                })
                .catch(err => {
                    console.error(err)
                })

        }
    }
    deleteItem = async event => {
        if (window.confirm("آیا میخواهید کامنت را حذف کنید ؟")) {

            await axios.delete(window.admin_base_URL + '/comment/delete/' + event.target.id)
                .then(res => {
                    alert(res.data.message)
                    this.getComments();
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }
    render() {
        let {comments ,pagination}  = this.state;
        return (
            <div className="ps-page pb-75">
                <BannderHead title={'لیست کامنت‌ها'}/>
                <div className='ml-4 mb-4'>
                    <Pagination pagination={pagination}/>
                </div>
                <table className="table table-hover table-striped  text-right mb-4">
                    <thead>
                    <tr>
                        <th scope="col">نام کاربر</th>
                        <th scope="col">شماره تلفن کاربر</th>
                        <th scope="col">متن پیام</th>
                        <th scope="col">امتیاز</th>
                        <th scope="col">وضعیت</th>
                    </tr>
                    </thead>
                    <tbody>
                    {comments.map((item, index) => {
                        return <tr key={index}>
                            <td>{item.clientName}</td>
                            <td>{item.clientPhone}</td>
                            <td>{item.message}</td>
                            <td>{item.rate}</td>
                            <td>{ item.enabled ? "فعال" : "غیر فعال"}</td>
                            <td>
                                {!item.enabled ? <button type="button" className="ps-btn ps-btn--outline ps-btn--sm" onClick={this.changeStatus} id={item._id}>تایید نمایش کامنت</button> : ""}
                                &nbsp;
                                <button type="button" className="ps-btn ps-btn--outline ps-btn--sm"
                                        onClick={this.deleteItem} id={item._id}>حذف
                                </button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
                <Pagination pagination={pagination} />
            </div>
        );
    }

}
