import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { ProductProvider } from "./context";
window.site_URL = "http://loafbakery.co/";
window.base_URL =  "http://api.loafbakery.co/" + 'api';
window.admin_base_URL =  "http://api.loafbakery.co/" + 'admin_api';
window.file_URL =  "http://api.loafbakery.co/";
// window.site_URL = "http://localhost:3000/";
// window.base_URL =  "http://localhost:8888/" + 'api';
// window.admin_base_URL =  "http://localhost:8888/" + 'admin_api';
// window.file_URL =  "http://localhost:8888/";
ReactDOM.render(
  <ProductProvider>
    <Router>
      <App />
    </Router>
  </ProductProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
