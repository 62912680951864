import React, { Component } from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";
import FileUpload from "../../components/FileUpload";
import MapInput from "../../components/map/MapInput";


export default class AdminBranchFormPage extends Component {
    state = {
        _id:null,
        enabled: '',
        title: '',
        description: '',
        address: '',
        phone: '',
        snapLink: '',
        location: [35.7219, 51.3347],
        imgs: [''],
        status: 0
    };

    async componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            await this.getBranch();
        }

    }

    getBranch = async () => {
        let data = {};
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');

        await axios.get(window.admin_base_URL + '/branch/read/' + myParam)
            .then(res => {
                data = res.data.result;
                if (!data.location || data.location.length < 2) {
                    data.location = [35.7219, 51.3347];
                }
            }).catch(err => {
                console.error(err)
            })
        data.imgs.push('');
        this.setState(() => {
            return { ...data };
        });

    }
    uploadedFile = (path) => {
        let images = this.state.imgs;
        images[images.length - 1] = path;
        images.push('');
        this.setState({ imgs: images })
    }
    onChangeLocation = (location) => {
        if (location) {
            this.setState({ location: location })
        }
    }
    delImage = event => {
        event.preventDefault();
        let index = parseInt(event.target.id);
        let images = this.state.imgs;
        delete images[index];
        this.setState({ imgs: images })
    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    createBranch = async event => {
        event.preventDefault();
        this.state.imgs.splice(this.state.imgs.indexOf(""), 1);
        this.state.imgs = this.state.imgs.filter(element => element !== null);
        const branch = this.state;
        let URL = window.admin_base_URL + "/branch/create"
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            URL = window.admin_base_URL + "/branch/update/" + myParam
            await axios.patch(URL, branch)
                .then(async () => {
                    await this.setState({
                        branch, status: 200
                    });
                    window.location.href = "/adminPanel/branches"
                })
                .catch(async err => {
                    await this.setState({
                        branch, status: 400
                    });
                    console.error(err)
                })
        } else {
            await axios.post(URL, branch)
                .then(() => {
                    this.setState({
                        branch, status: 200
                    });
                    window.location.href = "/adminPanel/branches"

                })
                .catch(async err => {
                    await this.setState({
                        status: 400
                    });
                    console.error(err)
                })
        }

    };

    render() {


        const {
            title,
            description,
            address,
            phone,
            location,
            enabled,
            snapLink,
            imgs,
            status

        } = this.state;
        return (
            <div id='AdminBranchForm'>
                <BannderHead title={'فرم ایجاد/ویرایش شعب'} />
                <form className="ps-form--adminPanel" onSubmit={this.createBranch}>

                    <div className="row justify-content-center ps-form__content" dir='rtl'>
                        <div className="col-12 col-md-9">
                            <div className="row">

                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            required
                                            className="form-control shadow-sm "
                                            placeholder='عنوان شعبه'
                                            type="text"
                                            name='title'
                                            value={title}
                                            onChange={this.handleChange}
                                        />
                                    </div>


                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control shadow-sm "
                                            placeholder='آدرس'
                                            required
                                            type="text"
                                            name='address'
                                            value={address}
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control shadow-sm "
                                            placeholder='توضیحات'
                                            type="text"
                                            name='description'
                                            value={description}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control shadow-sm "
                                            placeholder='لینک اسنپ فود'
                                            type="text"
                                            name='snapLink'
                                            value={snapLink}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control shadow-sm "
                                            placeholder='شماره تلفن'
                                            type="number"
                                            required
                                            name='phone'
                                            value={phone}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <select className=" form-control shadow-sm" value={enabled}
                                            name='enabled'
                                            onChange={this.handleChange}>
                                            <option value="" disabled>وضعیت</option>
                                            <option value={true}>فعال</option>
                                            <option value={false}>غیر فعال</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        {imgs.map((item, index) => {
                                            return <div className='d-flex mb-4' key={index}>
                                                <FileUpload onUploadFile={this.uploadedFile} index={index} name='img' value={item} />
                                                <button className=' btn btn-lg rounded-circle' id={index}
                                                    onClick={this.delImage} item={item}>

                                                    حذف

                                                </button>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        {location ? <MapInput dragable location={location} onChangeLocation={this.onChangeLocation} /> : ''}
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    {status === 200 ?
                                        <div className="alert alert-success text-right" role="alert">
                                            درخواست با موفقیت ثبت شد
                                        </div> : ''
                                    }
                                    {status === 400 ?
                                        <div className="alert alert-danger text-right" role="alert">
                                            خطا در ثبت اطلاعات رخ داده است.
                                        </div> : ''
                                    }
                                    <div className="form-group">
                                        <button
                                            className="ps-btn  ps-btn--sm mr-auto"
                                            type="submit">ثبت
                                            مشخصات
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>


            </div>
        );
    }
}
