import React, { Component } from "react";
import BlogCard from "../../components/BlogCard";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";

export default class BlogsPage extends Component {
    state = {
        blogs:[],
        page:0,
    }
    async componentDidMount() {
        await this.getBlogs();
    }
    getBlogs = async () => {
        let page = this.state.page + 1
        // let page = 2
        let data = [];
        let Url = window.base_URL + '/blog/list'
        if (page) {
            Url = window.base_URL + '/blog/list?page=' + page
         }
        await axios.get(Url).then(res => {
            data = res.data.result
        }).catch(err=>{
            console.error(err)
        })
        this.setState(() => {
            return {blogs: [...this.state.blogs ,...data],page:page};
        });
    }


    render() {
        let {blogs} = this.state
        return (
            <div className="ps-page pb-75">
                <BannderHead title={'بلاگ‌ها'}/>
                <div className="container">
                    <div className="row">
                        {blogs.map(blog => {
                            return <div key={blog.id} className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 ">
                                <BlogCard  blog={blog}/>
                            </div>
                        })}
                    </div>
                </div>
                <div className="text-center "><button onClick={this.getBlogs} className="ps-btn ps-btn--outline">بارگذاری بیشتر</button></div>
            </div>
        );
    }
}
