import React, { Component } from "react";
import BannderHead from "../components/layout/BannderHead";
import axios from "axios";
import MapInput from "../components/map/MapInput";

export default class ContactUsPage extends Component {
    state = {
        contactUs: {
            name: '',
            phone: '',
            message: '',
        },
        status: 0,
        configs: {
            _id: null,
            title: '',
            instagram: '',
            phone: '',
            location: [35.7219, 51.3347]
        }
    }

    constructor(props) {
        super(props);
        let configs = JSON.parse(sessionStorage.getItem('configs'));
        this.state.configs = configs
    }

    componentDidMount() {
        let configs = JSON.parse(sessionStorage.getItem('configs'));

        this.setState({
            configs
        })
    }

    contactUs = async event => {
        event.preventDefault();
        const { contactUs } = this.state;
        let URL = window.base_URL + "/contactUs/create"
        await axios.post(URL, contactUs)
            .then(() => {
                this.setState({
                    contactUs, status: 200
                });

                alert("پیام شما ارسال شد ")
            })
            .catch(err => {
                this.setState({
                    contactUs, status: 400
                });
                alert(err)
                console.error(err)
            })


    };
    handleChange = event => {
        this.setState({
            contactUs: {
                ...this.state.contactUs,
                [event.target.name]: event.target.value
            }
        });

    };
    onChangeLocation = (location) => {
        if (location && false) {
            this.setState({ configs: { ...this.state.configs, location } })
        }
    }

    render() {
        const {
            name,
            phone,
            message,
        } = this.state.contactUs;

        const { configs } = this.state;
        return (
            <div className="ps-page--profile ">
                <BannderHead title={' تماس با ما'} />
                <div className="ps-section ps-home-book-table dark bg--cover"
                    data-background="img/book-table.jpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                                <form className="ps-form--book-your-table" onSubmit={this.contactUs}>
                                    <div className="ps-form__header">
                                        <h3>ارسال پیام</h3>
                                    </div>
                                    <div className="ps-form__content" dir='rtl'>
                                        <div className="form-group">
                                            <input
                                                required
                                                className="form-control" type="text"
                                                placeholder="نام / نام خانوادگی / نام فروشگاه"
                                                name='name'
                                                value={name}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                required
                                                className="form-control no-spinners"
                                                type="number" placeholder="شماره تماس همراه"
                                                name='phone'
                                                value={phone}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                required
                                                className="form-control"
                                                type="text"
                                                name='message'
                                                value={message}
                                                onChange={this.handleChange}
                                                placeholder="متن پیام" />
                                        </div>

                                    </div>
                                    <div className="ps-form__footer">
                                        <button className="ps-btn" type="submit">ارسال</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                                <div className="ps-section__header">
                                    <a className="ps-logo white-logo" href='/'>
                                        <img className='mb-20' src={window.site_URL + "logo.png"} height='128px'
                                            alt="" />
                                    </a>

                                </div>
                                <div className="ps-section__content">
                                    <div className="ps-block--upcomming-event">
                                        <div className="ps-block__content">
                                            <p className="mb-30">
                                                <a href={configs.instagram}>
                                                    <span className="hover:ps-hightlight"> instagram
                                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                            width="48" height="48" viewBox="0 0 48 48">
                                                            <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899"
                                                                gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#fd5"></stop><stop offset=".328"
                                                                    stopColor="#ff543f"></stop><stop
                                                                        offset=".348" stopColor="#fc5245"></stop><stop offset=".504" stopColor="#e64771"></stop><stop offset=".643"
                                                                            stopColor="#d53e91"></stop><stop
                                                                                offset=".761" stopColor="#cc39a4"></stop><stop offset=".841" stopColor="#c837ab"></stop></radialGradient><path
                                                                                    fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
                                                                                    d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient
                                                                                        id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786"
                                                                                        cy="5.54" r="29.813"
                                                                                        gradientTransform="matrix(1 0 0 .6663 0 1.849)"
                                                                                        gradientUnits="userSpaceOnUse"><stop offset="0"
                                                                                            stopColor="#4168c9"></stop><stop
                                                                                                offset=".999" stopColor="#4168c9" stopOpacity="0"></stop></radialGradient><path
                                                                                                    fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
                                                                                                    d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path
                                                                                                        fill="#fff"
                                                                                                        d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle
                                                                                                            cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path
                                                                                                                fill="#fff"
                                                                                                                d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                                                        </svg></span>
                                                </a>
                                            </p>
                                            <p>
                                                <span>شماره تماس:</span>
                                                <span className="ps-hightlight"> {configs.phone}</span>
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {configs._id ? <MapInput location={configs.location} onChangeLocation={this.onChangeLocation} /> : ''}


            </div>
        );
    }
}
