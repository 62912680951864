import React, { Component } from "react";
import $ from "jquery";
import 'owl.carousel/dist/assets/owl.carousel.css';
import BannderHead from "../components/layout/BannderHead";
import axios from "axios";
import MapInput from "../components/map/MapInput";
// import owl from 'owl.carousel';
window.jQuery = $;
let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;


export default class BranchDetailsPage extends Component {
    state = {
        _id: null,
        enabled: '',
        title: '',
        description: '',
        address: '',
        phone: '',
        link: '',
        location: ['', ''],
        imgs: [''],
        status: 0
    };

    async componentDidMount() {


        await this.getBranch();

    }

    getBranch = async () => {
        const { id } = this.props.match.params;
        let data = [];
        await axios.get(window.base_URL + '/branch/read/' + id)
            .then(res => {
                data = res.data.result
            }).catch(err => {
                console.error(err)
            })

        this.setState(() => {
            return { ...data };
        });

        async function owlRunner() {


            function setAnimation(_elem, _InOut) {
                // Store all animationend event name in a string.
                // cf animate.css documentation
                var animationEndEvent = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

                _elem.each(function () {
                    var $elem = $(this);
                    var $animationType = 'animated ' + $elem.data('animation-' + _InOut);

                    $elem.addClass($animationType).one(animationEndEvent, function () {
                        $elem.removeClass($animationType); // remove animate.css Class at the end of the animations
                    });
                });
            }

            var target = $('.owl-slider');

            if (target.length > 0) {
                target.each(function () {
                    var el = $(this);

                    if (target.children('div, span, a, img, h1, h2, h3, h4, h5, h5').length >= 2) {
                        el.owlCarousel({
                            animateIn: '',
                            animateOut: '',
                            margin: 0,
                            autoplay: true,
                            autoplayTimeout: 5000,
                            autoplayHoverPause: true,
                            loop: true,
                            nav: false,
                            mouseDrag: true,
                            touchDrag: true,
                            autoplaySpeed: 1000,
                            navSpeed: 1000,
                            dotsSpeed: 1000,
                            dragEndSpeed: 1000,
                            navText: [<i className='icon-chevron-left'></i>, <i className='icon-chevron-right'></i>],
                            dots: false,
                            items: 1,
                            responsive: {
                                0: {
                                    items: 1
                                },
                                480: {
                                    items: 2
                                },
                                768: {
                                    items: 2
                                },
                                992: {
                                    items: 3
                                },
                                1200: {
                                    items: 3
                                },
                                1680: {
                                    items: 4
                                }
                            },
                            rtl: false
                        }).trigger('refresh.owl.carousel');
                        // Fired before current slide change
                        el.on('change.owl.carousel', function (event) {
                            var $currentItem = $('.owl-item', el).eq(event.item.index);
                            var $elemsToanim = $currentItem.find("[data-animation-out]");
                            setAnimation($elemsToanim, 'out');
                        });

                        // Fired after current slide has been changed
                        el.on('changed.owl.carousel', function (event) {
                            var $currentItem = $('.owl-item', el).eq(event.item.index);
                            var $elemsToanim = $currentItem.find("[data-animation-in]");
                            setAnimation($elemsToanim, 'in');
                        })


                    }

                });
            }
        }

        await owlRunner()


    }
    onChangeLocation = (location) => {
        if (location && false) {
            this.setState({ location: location })
        }
    }

    render() {
        const {
            _id,
            title,
            description,
            address,
            phone,
            location,
            imgs,
            snapLink,

        } = this.state;
        return (
            <div className="ps-page--about branch-detail-page">
                <BannderHead title={title} />
                <div className="ps-section ps-home-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ">

                                <div className="ps-section__content">
                                    <p>{description}</p>
                                    <p><b>آدرس :</b> {address}</p>
                                    <p><b>شماره تماس :</b> {phone}</p>
                                    <p>
                                        <a href={"geo:" + location[0] + "," + location[1]} className='mx-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48"> <path fill="#48b564" d="M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06 C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88 C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z"></path><path fill="#fcc60e" d="M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15 c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z"></path><path fill="#2c85eb" d="M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z"></path><path fill="#ed5748" d="M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3 L19.83,14.92z"></path><path fill="#5695f6" d="M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74 c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z"></path> </svg>
                                        </a>
                                        <a href={snapLink} className='snappfood mx-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.26 85.63" width='64' >
                                                <g id="Layer_2" data-name="Layer 2">
                                                    <g id="Layer_1-2" data-name="Layer 1">
                                                        <path className="cls-1"
                                                            d="M106.26,62.05v-.91c0-5.28,0-10.56,0-15.84,0-.66.17-.85.83-.83q3.65.06,7.29,0c.65,0,.78.19.78.8q0,19.36,0,38.73c0,.58-.15.76-.74.75-2.43,0-4.87,0-7.3,0-.56,0-.72-.18-.69-.72s0-1.23,0-1.84c-.07,0-.15,0-.16,0-2.23,2.92-5.32,3.62-8.74,3.3-6.88-.65-11.24-6.67-11.26-12.87,0-5.22,1.9-9.5,6.56-12.08a11.06,11.06,0,0,1,12,.36A17.46,17.46,0,0,1,106.26,62.05Zm.33,10.31A5.68,5.68,0,1,0,101,78,5.37,5.37,0,0,0,106.59,72.36Z"></path>
                                                        <path className="cls-1"
                                                            d="M127.83,35.39c0,1.2,0,2.25,0,3.3a46.37,46.37,0,0,1-.09,4.86A5.11,5.11,0,0,1,122.86,48c-1,0-2,0-3,0-.69,0-.9-.15-.9-.86,0-3.58,0-7.16,0-10.74,0-7.63,0-15.26,0-22.89,0-.83.23-1,1-1,2.35.06,4.7.05,7,0,.69,0,.88.2.83.85s0,1.2,0,1.81c.14,0,.22,0,.24,0,2.7-3.58,8-4.23,12.07-2.47,4.69,2,7.06,5.76,7.67,10.68a13.75,13.75,0,0,1-2.69,10.35c-3.62,4.77-10.62,6.14-15.48,3A16.93,16.93,0,0,1,127.83,35.39Zm5.45-15.88a5.4,5.4,0,0,0-5.73,5.56,5.69,5.69,0,0,0,11.37.11A5.46,5.46,0,0,0,133.28,19.51Z"></path>
                                                        <path className="cls-1"
                                                            d="M97.26,15.35a8.43,8.43,0,0,1,4.38-3.06,12,12,0,0,1,13,4.52A14.27,14.27,0,0,1,113,35.13,12,12,0,0,1,99.38,37a9.83,9.83,0,0,1-2.17-1.69,1.18,1.18,0,0,0-.14.87c0,2.27,0,4.53,0,6.8a5.15,5.15,0,0,1-5,5c-1.09.05-2.18,0-3.27,0-.45,0-.61-.17-.57-.59a1.93,1.93,0,0,0,0-.34q0-16.77,0-33.54c0-.77.2-1,1-1,2.37.05,4.75,0,7.12,0,.6,0,.79.17.74.76s0,1.26,0,1.9Zm-.41,9.8a5.32,5.32,0,0,0,5.68,5.56,5.39,5.39,0,0,0,5.67-5.61,5.46,5.46,0,0,0-5.72-5.59A5.36,5.36,0,0,0,96.85,25.15Z"></path>
                                                        <path className="cls-1"
                                                            d="M4,26.71a16.89,16.89,0,0,0,4.85,3.06,8.94,8.94,0,0,0,5.26.46,4,4,0,0,0,2-1.13,3,3,0,0,0-.29-4.57,10.7,10.7,0,0,0-3.72-1.68A47.46,47.46,0,0,1,6.56,21a8.11,8.11,0,0,1-5.18-7.54c-.22-3.81.63-7.21,3.38-10A11.75,11.75,0,0,1,12.39.07,25.66,25.66,0,0,1,24.68,2.43c.47.21.67.4.36.93-.41.69-.73,1.44-1.11,2.15a5.23,5.23,0,0,1-6,2.69,10.12,10.12,0,0,0-3.24-.31,4.68,4.68,0,0,0-2,.69,2.59,2.59,0,0,0-.05,4.64,15.76,15.76,0,0,0,4,1.57A37.09,37.09,0,0,1,21,16.32a9.43,9.43,0,0,1,5.89,10.24,12.86,12.86,0,0,1-2.37,7A13,13,0,0,1,16,38.26,21.67,21.67,0,0,1,.4,34.71c-.34-.22-.54-.38-.29-.84C1.41,31.53,2.67,29.16,4,26.71Z"></path>
                                                        <path className="cls-1"
                                                            d="M76.94,34.72c-3.53,4.86-11,4.44-15.28,1.07-7-5.54-6.52-17.38,1-22.15,4.28-2.7,10.44-2.37,13.82,1,0,0,.1,0,.2.09a4.53,4.53,0,0,0,.16-1.55c0-.42.12-.58.57-.58q3.9,0,7.8,0c.44,0,.57.16.57.58,0,6.54,0,13.08,0,19.61a5.09,5.09,0,0,1-4.85,4.78c-1.12,0-2.24,0-3.35,0-.48,0-.65-.15-.62-.62C77,36.32,76.94,35.62,76.94,34.72Zm.11-9.62a5.69,5.69,0,0,0-11.37,0,5.69,5.69,0,0,0,11.37,0Z"></path>
                                                        <path className="cls-1"
                                                            d="M39,85.62a15.9,15.9,0,0,1-10.91-3.76A12.16,12.16,0,0,1,24,71.42c.43-6.78,5.45-10.87,11.42-12a17.48,17.48,0,0,1,11.94,1.69A12.14,12.14,0,0,1,54,72.78c-.14,5.45-3,9.23-7.89,11.44A16.4,16.4,0,0,1,39,85.62ZM33.3,72.4A5.37,5.37,0,0,0,39,78a5.59,5.59,0,1,0,0-11.18A5.35,5.35,0,0,0,33.3,72.4Z"></path>
                                                        <path className="cls-1"
                                                            d="M70.06,85.63c-4.43,0-8.42-1.24-11.64-4.45-5.09-5.08-5.06-16,3.71-20.28a17.42,17.42,0,0,1,15.84,0c4.91,2.39,7.25,6.53,7.07,11.94s-3,9.17-7.88,11.37A16.78,16.78,0,0,1,70.06,85.63ZM70,66.79a5.59,5.59,0,1,0,5.71,5.56A5.37,5.37,0,0,0,70,66.79Z"></path>
                                                        <path className="cls-1"
                                                            d="M2.75,66.48c0-5.82,0-11.63,0-17.44,0-.67.12-.9.85-.89q9.69,0,19.37,0c.67,0,.83.2.82.84,0,2.12-.06,4.25,0,6.37,0,.79-.26.9-.95.9-3.24,0-6.48,0-9.72,0-.65,0-.87.15-.85.83.05,1.5,0,3,0,4.52,0,.58.16.77.75.76,2.94,0,5.87,0,8.81,0,.66,0,.92.13.9.86q-.07,3.22,0,6.45c0,.74-.24.87-.9.86-2.91,0-5.81,0-8.72,0-.66,0-.84.17-.84.83,0,4.19,0,8.38,0,12.57,0,.71-.12,1-.9,1q-3.9-.07-7.8,0c-.71,0-.86-.21-.86-.89C2.77,78.1,2.75,72.29,2.75,66.48Z"></path>
                                                        <path className="cls-1"
                                                            d="M37.69,15.88a1.15,1.15,0,0,1,.09-.33c2.06-3.05,5.09-3.9,8.55-3.7a12,12,0,0,1,2.31.37c3.52.91,5.69,3.59,6.11,7.54.38,3.62.09,7.26.17,10.89a15.15,15.15,0,0,1-.06,2.43,5.23,5.23,0,0,1-5.26,4.49c-.92,0-1.84,0-2.76,0-.65,0-.8-.21-.79-.82q0-6,0-12.07A13.22,13.22,0,0,0,45.9,22a3.46,3.46,0,0,0-3.19-3.15,4.2,4.2,0,0,0-4.57,2.21,7.61,7.61,0,0,0-.66,3.26c0,2.57,0,5.14,0,7.71a5.27,5.27,0,0,1-5.39,5.51c-.87,0-1.74,0-2.6,0-.7,0-.9-.17-.89-.88,0-7,0-14.08,0-21.13,0-.75,0-1.51,0-2.26,0-.52.1-.74.69-.73,2.48,0,5,0,7.46,0,.5,0,.7.13.67.66,0,.82,0,1.64,0,2.47Z"></path>
                                                        <path className="cls-1"
                                                            d="M155.28,24.53c-.75,0-1.51,0-2.26,0s-.74-.17-.58-.76c.75-2.8,1.45-5.61,2.17-8.42,1.15-4.51,2.32-9,3.45-13.53.15-.6.36-.86,1-.85,2.52.05,5,0,7.54,0,.57,0,.72.12.57.72-1.58,6.18-3.12,12.37-4.7,18.56A5.33,5.33,0,0,1,157,24.53Z"></path>
                                                        <path className="cls-1"
                                                            d="M160.93,32.9a5.68,5.68,0,0,1-11.35,0,5.46,5.46,0,0,1,5.71-5.68A5.34,5.34,0,0,1,160.93,32.9Z"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                {_id ? <a href={"geo:" + location[0] + "," + location[1]} className='text-center'>
                                    <MapInput location={location} onChangeLocation={this.onChangeLocation} />
                                </a> : ''}

                            </div>

                        </div>
                    </div>
                </div>

                <div className="owl-slider owl-carousel">

                    {imgs.map((img, index) => {
                        return <img src={window.file_URL + img} alt="" key={index} />

                    })}
                </div>
            </div>
        );
    }
}
