import React, { Component } from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";


export default class AdminSettingPage extends Component {
    state = {
        err:{},
        form: {
            oldpassword:'',
            newpassword:'',
        },
        status: 0
    }

    
    updatePassword = async event => {
        event.preventDefault();
        const { form } = this.state;
        let URL = window.admin_base_URL + "/admin/updatePassword"
        
        
            await axios.post(URL, form)
                .then(() => {
                    this.setState({
                        form, status: 200,err:{}
                    });
                    window.location.href = "/adminPanel/"
                })
                .catch(async err => {
                    await this.setState({
                        form, status: 400,err: err.response
                    });
                    console.error(err)
                })

    };
    handleChange = event => {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value
            }
        });

    };


    render() {
        const {
            oldpassword,
            newpassword,
        } = this.state.form;
        const { status,err } = this.state;
        return (
            <div>
                <BannderHead title={'تغییر رمز عبور'} />
                <form className="ps-form--adminPanel" onSubmit={this.updatePassword}>
                    <div className="row ps-form__content" dir='rtl'>
                        
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='رمز عبور قبلی'
                                    type="password"
                                    name='oldpassword'
                                    required
                                    value={oldpassword || ""}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='رمز عبور جدید'
                                    type="password"
                                    name='newpassword'
                                    required
                                    value={newpassword || ""}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>

                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-5 text-right'>
                            {status === 200 ?
                                <div className="alert alert-success text-right" role="alert">
                                    درخواست با موفقیت ثبت شد
                                </div> : ''
                            }
                            {status === 400 ?
                                <div className="alert alert-danger text-right" role="alert">
                                    {err.data.message}
                                </div> : ''
                            }
                            <div className="form-group ">
                                <button className="ps-btn  ps-btn--sm mr-auto" type="submit">تغییر رمز عبور
                                </button>
                            </div>
                        </div>

                    </div>

                </form>
            </div>

        );
    }
}
