import React, { Component } from 'react';
import axios from 'axios';

export default class FileUpload extends Component {
    state = {
        file: null,
        setFile: null,
        path: null
    }



    onUploadFile = (path) => {
        this.props.onUploadFile(path);
    }


    handleSubmit = async (e) => {
        e.preventDefault();
        // this.setState({ file: e.target.files[0] });
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        try {
            await axios.post(window.admin_base_URL + "/file/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.setState({ path: res.data.path })
                this.onUploadFile(res.data.path);

            }).catch(err => {
                console.error(err);
            })

        } catch (error) {
            console.error('Error uploading file:', error);

        }
    }
    // removeFile = async (e) => {
    //     e.preventDefault();
    //     this.setState({ path: '' })
    //     this.onUploadFile('');
    // }

    render() {
        return (
            <div className='w-100 image-uploader d-flex'>

                <label className=" form-control shadow-sm cursor-pointer d-flex align-items-center flex-grow-1 ml-3">
                    <input type="file"
                           placeholder="تصویر"
                           onChange={this.handleSubmit}
                           className="form-control shadow-sm" />
                    <span className={this.props.value ? 'text-muted h5':'text-muted'}>
                        {this.props.value ? this.props.value : `${this.props.title ? this.props.title : 'ارسال تصویر'}`}
                    </span>
                </label>
                {this.props.value ? <img src={window.file_URL + this.props.value}  height={44}/> : ''}
                {/*<button className='border-0 bg-transparent ps-btn--outline ps-btn--close' onClick={this.removeFile}></button>*/}
                {/*{this.state.path ? <img src={window.file_URL + this.state.path}  height={44}/> : ''}*/}


                
            </div>
        );
    }
};

