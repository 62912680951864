import React, {Component} from "react";
import {PropTypes} from "prop-types";


export default class Pagination extends Component {
    prevPage(){
        const urlParams = new URLSearchParams(window.location.search);
        let page = urlParams.get('page');
        if (page) {
            page--
        } else {
            page = 1
        }
        window.location.href = "?page=" + page
    }
    nextPage(){
        const urlParams = new URLSearchParams(window.location.search);
        let page = urlParams.get('page');
        if (page) {
            page++
        } else {
            page = 2
        }

        window.location.href = "?page=" + page
    }

    render() {
        const {pagination} = this.props

        if (this.props.pagination){
            return (
                <div className='d-inline-block'>
                    {pagination.page != 1 ? <button className="ps-btn--outline ps-btn--sm mx-1" onClick={this.prevPage}>صفحه قبل</button> : '' }
                    {pagination.page != pagination.pages ? <button className="ps-btn--outline ps-btn--sm mx-1" onClick={this.nextPage}>صفحه بعد</button> :''}
                </div>
            );
        }

    }
}
Pagination.propTypes = {
    pagination: PropTypes.shape({
        page: PropTypes.number,
        pages: PropTypes.number,
        limit: PropTypes.number,
        skip: PropTypes.number,
        count: PropTypes.number,
    }).isRequired
};