import React, { Component } from "react";
import ProductCard from "../../components/Product/ProductCard";
import axios from "axios";
import ButtonCount from "../../components/Product/ButtonCount";

export default class ProductDetails extends Component {

    state = {
        relatedProducts: [],
        product: {},
    }

    async componentDidMount() {
        await this.getProduct();
        await this.getCategory();
        await this.getRelatedProducts();
    }
    getRelatedProducts = async () => {
        let data = [];
        await axios.get(window.base_URL + '/product/list?items=4').then(res => {
            data = res.data.result
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return { relatedProducts: data };
        });
    };
    getProduct = async () => {
        const { id } = this.props.match.params;
        let data = [];
        await axios.get(window.base_URL + '/product/read/' + id)
            .then(res => {
                data = res.data.result;
                this.setState({ product: data });
            }).catch(err => {
                console.error(err)
            })
        await axios.put(window.base_URL + '/product/read/' + id);
    };
    getCategory = async () => {
        const id = this.state.product.category;
        let data = [];
        await axios.get(window.base_URL + '/category/read/' + id)
            .then(res => {
                data = res.data.result
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return {
                product: {
                    ...this.state.product,
                    category: data
                }
            };
        });
    }
    addToBasket = async () => {
        let order = {
            products: []
        }
        if (JSON.parse(sessionStorage.getItem('order')) && JSON.parse(sessionStorage.getItem('order')) !== {}) {
            order = JSON.parse(sessionStorage.getItem('order'))
        }
        order.products.push(this.state.product)
        order.products = order.products.filter(item => item !== null);
        sessionStorage.setItem('order', JSON.stringify(order));
        this.state.count += 1
        this.setState(() => {
            return { count: this.state.count, status: 200 };
        });
        alert('به سبد خرید اضافه شد')
    };

    render() {
        let { relatedProducts, product, status } = this.state
        return (
            <div className="ps-page ps-page--product-detail">
                <div className="ps-product--detail ps-product--background bg--cover"
                    data-background="/img/page-header.png">
                    <div className="container">
                        <div className="ps-product__header">
                            <div className="ps-product__thumbnail" data-vertical="true">
                                <figure>
                                    <div className="ps-wrapper">
                                        <div className="ps-product__gallery" data-arrow="true">
                                            <div className="item">
                                                <a href={window.file_URL + product.img}>
                                                    <img src={window.file_URL + product.img} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                            <div className="ps-product__info">
                                <h1>{product.title}</h1>
                                <h4 className="ps-product__price">
                                    <span>{product.price}</span>
                                    <sub>تومان</sub>
                                </h4>
                                <div className="ps-product__desc">
                                    <p>{product.description}</p>
                                </div>
                                <div className="ps-product__specification">
                                    {product.timeToCook ? <p><strong className='ml-2 mr-1'>زمان انتظار:</strong> {product.timeToCook}
                                        <sub>ساعت</sub></p> : ''}
                                    {product.weight ? <p><strong className='ml-2 mr-1'>سایز:</strong>{product.weight}&nbsp;<sub>گرم</sub>&nbsp;</p> : ''}
                                    {product.category ? <p><strong className='ml-2 mr-1'>دسته بندی:</strong><a
                                        href="shop-default.html">{product.category.title}</a>
                                    </p> : ''}
                                </div>
                                <div className="ps-product__shopping">
                                    {!!this.state.product._id ? <ButtonCount id={this.state.product._id} product={this.state.product} /> : ''}

                                    {/*<a className="ps-btn text-white" onClick={this.addToBasket}>افزودن به سبد</a>*/}

                                </div>
                                {status === 200 ?
                                    <div className="alert alert-success text-right" role="alert">
                                        درخواست با موفقیت ثبت شد
                                    </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ps-section ps-related-product">
                    <div className="container">
                        <div className="ps-section__header">
                            <h3>محصولات مرتبط</h3><i className="chikery-tt3"></i>
                        </div>
                        <div className="ps-section__content">
                            <div className="row">
                                {relatedProducts.map((product, index) => {
                                    return <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 " key={index}>
                                        <ProductCard product={product} />
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="ps-section__footer"><a className="ps-btn ps-btn--outline" href="/products">همه
                            محصولات</a></div>
                    </div>
                </div>
            </div>
        );
    }
}
