import React, { Component } from "react";
import Product from "./Product";
import { PropTypes } from "prop-types";
import { map } from "lodash";


const orderChange = new Event("orderChange");
const totalPriceChange = new Event("totalPriceChange");


export default class ButtonCount extends Component {
    state = {
        count: 0
    }


    constructor(props) {
        super(props);
        if (JSON.parse(sessionStorage.getItem('order')) && JSON.parse(sessionStorage.getItem('order')) !== {}) {
            let order = JSON.parse(sessionStorage.getItem('order'));
            let data = this.countOccurrences(order.products, props.product._id)
            this.state.count = data;
        }
    }


    componentDidMount() {
        window.addEventListener(
            "orderChange",
            (e) => {
                if (JSON.parse(sessionStorage.getItem('order')) && JSON.parse(sessionStorage.getItem('order')) !== {}) {
                    let order = JSON.parse(sessionStorage.getItem('order'));
                    let data = this.countOccurrences(order.products, this.props.product._id)
                    this.setState({ count: data })
                }
            },
            false,
        );
    }

    countOccurrences = (arr, val) => {
        return arr.reduce((a, v) => (v && v._id === val ? a + 1 : a), 0);
    }

    increaseProduct = async () => {
        let order = JSON.parse(sessionStorage.getItem('order')) || { products: [] };

        order.products.push(this.props.product)

        order.products = order.products.filter(item => item !== null);
        sessionStorage.setItem('order', JSON.stringify(order));
        this.state.count++;
        this.setState({ count: this.state.count });
        window.dispatchEvent(orderChange);
        window.dispatchEvent(totalPriceChange);
    };
    decreaseProduct = async () => {
        // if (this.state.count > 0) {
        let order = JSON.parse(sessionStorage.getItem('order')) || {};

        let i = order.products.findIndex(obj => obj && obj._id === this.props.product._id)
        delete order.products[i]

        order.products = order.products.filter(item => item !== null);
        sessionStorage.setItem('order', JSON.stringify(order));
        this.state.count--;
        this.setState({ count: this.state.count });
        window.dispatchEvent(orderChange);
        window.dispatchEvent(totalPriceChange);
        // }
    };

    changeProductCount = async (e) => {
        let order = JSON.parse(sessionStorage.getItem('order')) || {};
        let product = this.props.product;
        let newOrder = {
            products: map(order.products, item => {
                if (item._id !== this.props.product._id) {
                    return item;
                }
            })
        }
        newOrder.products = newOrder.products.filter(item => !!item);
        let i = 0;
        while (i < e.target.value) {
            newOrder.products.push(product);
            i++;
        }
        sessionStorage.setItem('order', JSON.stringify(newOrder));
        this.setState({ count: e.target.value });
        window.dispatchEvent(orderChange);
        window.dispatchEvent(totalPriceChange);
    };

    clickOnInput = async (e) => {
        e.target.select();
    }


    render() {
        const { count } = this.state
        return (
            <div className="form-group--number ">
                <button className="up" onClick={this.increaseProduct}></button>
                <button className="down" onClick={this.decreaseProduct}></button>
                <input
                    className="form-control no-spinners"
                    type="number"
                    value={count}
                    onChange={this.changeProductCount}
                    onClick={this.clickOnInput} />
            </div>
        );
    }
}
Product.propTypes = {
    product: PropTypes.shape({
        _id: PropTypes.string,
        img: PropTypes.string,
        title: PropTypes.string,
        price: PropTypes.string,
        weight: PropTypes.string,
        timeToCook: PropTypes.string,
    }).isRequired
};