import React, { Component } from "react";
import BannderHead from "../components/layout/BannderHead";
import ButtonCount from "../components/Product/ButtonCount";
import axios from "axios";
import { groupBy, orderBy } from "lodash";

var moment = require('moment-jalaali')



export default class CartPage extends Component {
    state = {
        id: null,
        totalPrice: 0,
        created: moment(new Date()).format('jYYYY/jMM/jDD HH:mm'),
        error: 0,
        order: {},
        maxTime: 0,
        result: [],
        description: '',
        disableOrder: false,
        success: null,
        loading: true,
    }

    constructor(props) {
        super(props);
        this.state.id = sessionStorage.getItem('editorder');

        let total = 0;
        this.state.order = JSON.parse(sessionStorage.getItem('order')) || {};
        if (this.state.order.products && this.state.order.products.length > 0) {
            this.state.order.products.forEach(item => {
                total += parseInt(item.price)
            })
            this.state.maxTime = this.state.order.products.reduce((max, obj) => {
                return Math.max(max, parseInt(obj.timeToCook));
            }, 0);
            this.state.result = groupBy(orderBy(this.state.order.products, "_id"), ({ _id }) => _id);
        }
        this.state.totalPrice = total;

        var d = new Date();
        if (d.getHours() < 22 && d.getHours() >= 7) {
            this.state.disableOrder = false;
        } else {
            this.state.disableOrder = true;
            this.state.id = null;
            sessionStorage.removeItem('editorder');
        }

        this.state.loading = false;
    }
    productMiniCart = (product, count, index) => {
        return <tr className='text-right' key={index}>
            <td>
                <div className="ps-product--cart">
                    <div className="ps-product__thumbnail">

                        <a className="ps-product__overlay" href={"/products/" + product._id}>
                            <img src={window.file_URL + product.img} alt="" />
                        </a>
                    </div>
                    <div className="ps-product__content">
                        <a className="ps-product__title" href={"/products/" + product._id}>{product ? product.title : ""}</a>
                    </div>
                </div>
            </td>
            <td>{product.price}</td>
            <td>
                <ButtonCount id={product._id} product={product} />
            </td>
            <td className="total">{(product.price * count).toLocaleString()}</td>
        </tr>
    }

    handleTarget = async (event) => {
        let target = event.target;
        this.setState(() => {
            return { howToSend: target.value, error: 0 };
        });
    }

    createOrder = async (event) => {
        if (!this.state.howToSend) {
            return this.setState(() => {
                return { error: 403 };
            });
        }
        let client = JSON.parse(sessionStorage.getItem('client'));
        if (!client) {
            return this.setState(() => {
                return { error: 404 };
            });
        }
        if (JSON.parse(sessionStorage.getItem('order')) && JSON.parse(sessionStorage.getItem('order')) !== {}) {
            this.setState({ loading: true });
            let order = JSON.parse(sessionStorage.getItem('order'));
            const data = {
                products: order.products.map(item => item._id),
                created: moment(new Date()).format('jYYYY/jMM/jDD HH:mm'),
                howToSend: this.state.howToSend,
                client: client._id,
                description: this.state.description
            }
            console.log(data);

            let URL = window.base_URL + '/order/create';
            if (this.state.id) {
                URL = window.base_URL + '/order/edit/' + this.state.id;
            }

            await axios.post(URL, { ...data }).then(async res => {
                await axios.put(window.base_URL + '/order/sms/' + res.data.result._id, {}).then(resp => {
                    let order = { products: [] }
                    sessionStorage.setItem('order', JSON.stringify(order));
                    sessionStorage.removeItem('editorder');
                    this.setState({
                        id: null,
                        totalPrice: 0,
                        created: moment(new Date()).format('jYYYY/jMM/jDD HH:mm'),
                        error: 0,
                        order: {},
                        result: [],
                        description: '',
                        disableOrder: false,
                        success: true,
                        loading: false,
                    })
                })
            }).catch(err => {
                console.error(err);
                this.setState({
                    created: moment(new Date()).format('jYYYY/jMM/jDD HH:mm'),
                    error: 500,
                    disableOrder: false,
                    success: false,
                    loading: false,
                })
            })
        } else {
            return this.setState(() => {
                return { error: 400 };
            });
        }
    }

    handleChange = event => {
        this.setState({
            description: event.target.value
        });
    }

    componentDidMount() {
        window.addEventListener(
            "totalPriceChange",
            (e) => {
                if (JSON.parse(sessionStorage.getItem('order')) && JSON.parse(sessionStorage.getItem('order')) !== {}) {
                    let order = JSON.parse(sessionStorage.getItem('order'));
                    let total = 0;
                    order.products.forEach(item => {
                        total += parseInt(item.price)
                    })
                    let result = groupBy(orderBy(order.products, "_id"), "_id");
                    this.setState({ totalPrice: total, order, result })
                }
            },
            false,
        );
    }

    render() {
        const { result, maxTime, disableOrder, description, loading, id } = this.state;
        return (
            <div className="ps-page" id='cartPage'>
                <BannderHead title={'سبد خرید'} />
                <div className="container">
                    <div className="ps-shopping-cart" dir='rtl'>
                        {id ?
                            <div className="alert alert-info text-right my-4" role="alert">شما در حال ویرایش سفارش امروز خود هستید. </div> : ''}
                        {disableOrder ?
                            <div className="alert alert-danger text-right my-4" role="alert">ثبت و ویرایش سفارش تنها تا ساعت ۲۲:۰۰ هر روز ممکن میباشد.</div> : <div className="alert alert-info text-right my-4" role="alert">ثبت و ویرایش سفارش تنها تا ساعت ۲۲:۰۰ هر روز ممکن میباشد.</div>}
                        <div className="table-responsive">
                            <table className="table ps-table ps-table--shopping-cart">
                                <thead>
                                    <tr className='text-right'>

                                        <th>نام محصول</th>
                                        <th>قیمت هر عدد</th>
                                        <th>تعداد</th>
                                        <th>مجموع</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(result)
                                        .sort((a, b) => a[0] > b[0] ? 1 : -1)
                                        .map((item, index) => {
                                            return this.productMiniCart(item[1][0], item[1].length, index)
                                        })}

                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    name='description'
                                    value={description}
                                    rows="3"
                                    placeholder="توضیحات سفارش"
                                    onChange={this.handleChange}
                                ></textarea>
                            </div>
                        </div>
                        {result.length > 0 ? <div className="alert alert-info text-right" role="alert">
                            یه علت زمانبر بودن فرایند آماده سازی سفارشات شما امکان ارسال سفارش تا {maxTime} ساعت آینده میسر نمیباشد
                        </div> : ''}
                        {this.state.error == 403 ?
                            <div className="alert alert-danger text-right my-4" role="alert">نوع ارسال سفارش را انتخاب
                                کنید </div> : ''}
                        <div className="d-flex align-items-center">
                            <h5 className=' d-md-block d-none text-right ' style={{ 'width': '10%' }}>
                                نوع ارسال
                            </h5>

                            <div className='d-md-flex d-block w-100'>

                                <button
                                    className={` shadow-sm m-2 rounded-pill form-control w-md-auto w-100 justify-content-center ${this.state.error == 404 ? 'border-danger' : ''} ${this.state.howToSend == 'fast' ? 'ps-btn--lg' : ''}`}
                                    onClick={this.handleTarget} value='fast'>ارسال فوری
                                </button>

                                <button
                                    className={` shadow-sm m-2 rounded-pill form-control w-md-auto w-100 justify-content-center ${this.state.error == 404 ? 'border-danger' : ''} ${this.state.howToSend == 'inPerson' ? 'ps-btn--lg' : ''}`}
                                    onClick={this.handleTarget} value='inPerson'>تحویل حضوری
                                </button>

                                <button
                                    className={` shadow-sm m-2 rounded-pill form-control w-md-auto w-100 justify-content-center ${this.state.error == 404 ? 'border-danger' : ''} ${this.state.howToSend == 'normal' ? 'ps-btn--lg' : ''}`}
                                    onClick={this.handleTarget} value='normal'>ارسال عادی
                                </button>
                            </div>
                        </div>
                        {id ?
                            <div className="alert alert-info text-right my-4" role="alert">شما در حال ویرایش سفارش امروز خود هستید. </div> : ''}

                        {disableOrder ?
                            <div className="alert alert-danger text-right my-4" role="alert">ثبت و ویرایش سفارش تنها تا ساعت ۲۲:۰۰ هر روز ممکن میباشد.</div> : <div className="alert alert-info text-right my-4" role="alert">ثبت و ویرایش سفارش تنها تا ساعت ۲۲:۰۰ هر روز ممکن میباشد.</div>}
                        {this.state.error == 404 ?
                            <div className="alert alert-danger text-right my-4" role="alert">برای ثبت سفارش اول وارد سایت شوید</div> : ''}
                        {this.state.error == 400 ?
                            <div className="alert alert-danger text-right my-4" role="alert">سبد خرید خالی است</div> : ''}
                        {this.state.success ?
                            <div className="alert alert-success text-right my-4" role="alert">سفارش شما با موفقیت ثبت شد. به منظور ویرایش سفارش از صفحه پروفایل بخش سوابق سفارشات اقدام کنید</div> : ''}
                        <div className="ps-section__actions">
                            <div className='d-flex justify-content-between mb-4'>
                                <h3>
                                    مجموع: <strong>{this.state.totalPrice.toLocaleString()}</strong><sub
                                        className='mx-1'>تومان</sub>
                                </h3>
                            </div>
                            <figure>
                                <button className="ps-btn text-white" onClick={this.createOrder}
                                    disabled={this.state.error == 404 || disableOrder || loading || result.length === 0}>ثبت نهایی</button>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
