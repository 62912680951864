import React, {Component} from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";


export default class AdminSliderListPage extends Component {
    state = {
        sliders:[]
    }
    async componentDidMount() {

        await this.getSliders();

    }
    goToEditForm = async event => {
        window.location.href = "slider/form?id=" + event.target.id
    }
    deleteItem = async event => {
        if (window.confirm("آیا میخواهید محصول را حذف کنید ؟")) {

            await axios.delete(window.admin_base_URL + '/slider/delete/' + event.target.id)
                .then(async res => {
                    alert(res.data.message)
                    await this.getSliders();
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }
    getSliders = async () => {
        let data = [];
        await axios.get(window.admin_base_URL + '/slider/list').then(res => {
            data = res.data.result
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return {sliders: data};
        });
    };


    render() {
        let tempdata = this.state.sliders;
        return (
            <div className="ps-page pb-75">
                <BannderHead title={'بنرهای اختصاصی'} />
                <div className='ml-4 mb-4'>
                    <a href='slider/form'
                       className="ps-btn  ps-btn--sm"><i className="fa fa-plus"></i>&nbsp;افزودن
                    </a>
                </div>
                <table className="table table-hover table-striped  text-right mb-4">
                    <thead>
                    <tr>
                        <th scope="col">شماره</th>
                        <th scope="col">عنوان</th>
                        <th scope="col">تصویر</th>
                        <th scope="col">لینک</th>
                        <th scope="col">عنوان لینک</th>
                        <th scope="col">وضعیت</th>
                        <th scope="col"></th>

                    </tr>
                    </thead>
                    <tbody>
                    {tempdata.map((item, index) => {
                        return <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.title}</td>
                            <td><img width='64' src={window.file_URL + item.img} alt={item.title}/></td>
                            <td>{item.link}</td>
                            <td>{item.textLink}</td>
                            <td>{item.enabled ? "فعال" : "غیر فعال"}</td>
                            <td>
                                <button type="button" className="ps-btn ps-btn--outline ps-btn--sm"
                                        onClick={this.goToEditForm} id={item._id}>ویرایش
                                </button>
                                &nbsp;
                                <button type="button" className="ps-btn ps-btn--outline ps-btn--sm"
                                        onClick={this.deleteItem} id={item._id}>حذف
                                </button>

                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}
