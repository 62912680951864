import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";


class AdminLoginForm extends React.Component {
    constructor() {
        super();

        this.state = {
            phone: "",
            password: "",
            isLoggedIn: false,
            error: null,
        };
    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    submitLogin = async event => {
        event.preventDefault();

        const { phone,password } = this.state;
        await axios.post(window.base_URL + "/admin/login", { phone,password })
            .then((res) => {
                sessionStorage.setItem('admin', JSON.stringify(res.data.result.admin));
                sessionStorage.setItem('token', JSON.stringify(res.data.result.token));
                var now = new Date();
                var time = now.getTime();
                var expireTime = time + (10 * 24 * 60 * 3600 * 1000);
                now.setTime(expireTime);
                document.cookie = "token=" + res.data.result.token + ';expires=' + now.toUTCString() + ';path=/';;
                axios.defaults.headers.common['x-auth-token'] = sessionStorage.getItem('token');

                window.location.href = "adminPanel"
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    this.setState({
                        error: err.response
                    });
                }
            })
    };

    render() {

        if (this.state.isLoggedIn) {
            return <Redirect to="/" />;
        }
        const { phone, password, error } = this.state;
        return (
            <div className='login-form card'>
                <form className='card-body text-right p-5'
                    onSubmit={this.submitLogin} dir='rtl'>
                    <div className="form-group">
                        <label htmlFor="phone">شماره تلفن</label>
                        <input
                            dir='ltr'
                            name='phone'
                            className="form-control rounded-pill no-spinners"
                            type="number"
                            value={phone}
                            onChange={this.handleChange}
                            placeholder="09..."
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">رمز عبور</label>
                        <input
                            dir='ltr'
                            className="form-control rounded-pill"
                            name='password'
                            type="password"
                            value={password}
                            placeholder="رمز عبور"
                            onChange={this.handleChange}
                        />
                    </div>
                    {error ?
                        <div className="alert alert-danger text-right" role="alert">{error.data.message}</div> : ''
                    }
                    <button type="submit" className='ps-btn'>ورود</button>
                </form>
            </div>
        );
    }


}

export default AdminLoginForm;
