import React, { Component } from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";
import MapInput from "../../components/map/MapInput";


export default class AdminConfigsPage extends Component {
    state = {
        config: {
            _id: null,
            title: '',
            instagram: '',
            phone: '',
            location: [35.7219, 51.3347]
        },
        status: 0
    }

    async componentDidMount() {
        await this.getConfigs();
    }

    getConfigs = async () => {
        let data = null;
        await axios.get(window.admin_base_URL + '/config/list').then(res => {
            data = res.data.result[0];
        })
        if (data) {
            if (data.location.length < 2) {
                data.location = [35.7219, 51.3347];
            }
            this.setState(() => {
                return { config: data };
            });
        }

    };
    updateConfigs = async event => {
        event.preventDefault();
        const { config } = this.state;
        let URL = window.admin_base_URL + "/config/create"
        const myParam = config._id;
        if (myParam) {
            URL = window.admin_base_URL + "/config/update/" + myParam
            await axios.patch(URL, config)
                .then(() => {
                    this.setState({
                        config, status: 200
                    });
                    window.location.href = "/adminPanel/configs"
                })
                .catch(async err => {
                    await this.setState({
                        config, status: 400
                    });
                    console.error(err)
                })
        } else {
            await axios.post(URL, config)
                .then(() => {
                    this.setState({
                        config, status: 200
                    });
                    window.location.href = "/adminPanel/configs"
                })
                .catch(async err => {
                    await this.setState({
                        config, status: 400
                    });
                    console.error(err)
                })
        }

    };
    handleChange = event => {
        this.setState({
            config: {
                ...this.state.config,
                [event.target.name]: event.target.value
            }
        });

    };

    onChangeLocation = (location) => {
        if (location) {
            this.setState({ config: { ...this.state.config, location } })
        }
    }

    render() {
        const {
            _id,
            title,
            instagram,
            phone,
            location,
        } = this.state.config;
        const { status } = this.state;
        return (
            <div>
                <BannderHead title={'تنظیمات عمومی'} />
                <form className="ps-form--adminPanel" onSubmit={this.updateConfigs}>
                    <div className="row ps-form__content" dir='rtl'>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='عنوان سایت'
                                    type="text"
                                    name='title'
                                    required
                                    value={title || ""}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='آدرس اینستاگرام'
                                    type="text"
                                    name='instagram'
                                    required
                                    value={instagram || ""}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>

                        
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm no-spinners"
                                    placeholder='شماره تلفن'
                                    type="number"
                                    name='phone'
                                    required
                                    value={phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                {_id ? <MapInput dragable location={location} onChangeLocation={this.onChangeLocation} /> : ''}


                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-5 text-right'>
                            {status === 200 ?
                                <div className="alert alert-success text-right" role="alert">
                                    درخواست با موفقیت ثبت شد
                                </div> : ''
                            }
                            {status === 400 ?
                                <div className="alert alert-danger text-right" role="alert">
                                    خطا در ثبت اطلاعات رخ داده است.
                                </div> : ''
                            }
                            <div className="form-group ">
                                <button className="ps-btn  ps-btn--sm mr-auto" type="submit">ثبت
                                    مشخصات
                                </button>
                            </div>
                        </div>

                    </div>

                </form>
            </div>

        );
    }
}
