import React, {Component} from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";

export default class AdminJobsFormPage extends Component {

    state = {
        categories: [],
        job: {
            enabled: '',
            title: '',
            link: '',
            description: '',

        },
        status: 0
    }

    async componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            await this.getJob();
        }

    }
    getJob = async () => {
        let data = [];
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');

        await axios.get(window.admin_base_URL + '/job/read/' + myParam)
            .then(res => {
                data = res.data.result
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return {job: data};
        });
    }
    handleChange = event => {
        this.setState({
            job: {
                ...this.state.job,
                [event.target.name]: event.target.value
            }
        });
    };
    createProduct = async event => {
        event.preventDefault();

        const {job} = this.state;
        let URL = window.admin_base_URL + "/job/create"
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            URL = window.admin_base_URL + "/job/update/" + myParam
            await axios.patch(URL, job)
                .then(async () => {
                    await this.setState({
                        job, status: 200
                    });
                    window.location.href = "/adminPanel/jobs"
                })
                .catch(async err => {
                    await this.setState({
                        job, status: 400
                    });
                    console.error(err)
                })
        } else {
            await axios.post(URL, job)
                .then(async () => {

                    await this.setState({
                        job, status: 200
                    });
                    window.location.href = "/adminPanel/jobs"
                })
                .catch(async err => {
                    await this.setState({
                        job, status: 400
                    });
                    console.error(err)
                })
        }

    };

    render() {

        const {
            title,
            link,
            description,
            enabled,
        } = this.state.job;
        const {status} = this.state;
        return (
            <div id='AdminProductForm'>
                <BannderHead title={"فرم ایجاد/ویرایش محصول"}/>
                <form className="ps-form--adminPanel" onSubmit={this.createProduct}>
                    <div className="row justify-content-center" dir='rtl'>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='عنوان'
                                    type="text"
                                    name='title'
                                    required
                                    value={title || ""}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm"
                                    type="text"
                                    placeholder='لینک'
                                    required
                                    name='link'
                                    value={link}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm"
                                    type="text"
                                    placeholder='توضیحات'
                                    required
                                    name='description'
                                    value={description}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <select className=" form-control shadow-sm" value={enabled}
                                        name='enabled'
                                        onChange={this.handleChange}>
                                    <option value="" disabled>وضعیت </option>
                                    <option value={true}>فعال</option>
                                    <option value={false}>غیر فعال</option>
                                </select>
                            </div>
                            {status === 200 ?
                                <div className="alert alert-success text-right" role="alert">
                                    درخواست با موفقیت ثبت شد
                                </div> : ''
                            }
                            {status === 400 ?
                                <div className="alert alert-danger text-right" role="alert">
                                    خطا در ثبت اطلاعات رخ داده است.
                                </div> : ''
                            }

                            <button className="ps-btn  ps-btn--sm mr-auto " type="submit">ثبت
                                مشخصات
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        );
    }
}
