import React, { Component } from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";
import Pagination from "../../components/Pagination";


export default class AdminCategoryListPage extends Component {
    state = {
        categories: [],
        pagination: {},
        search:''
    }
    async componentDidMount() {

        await this.getCategories();

    }

    getCategories = async () => {
        let URL = window.admin_base_URL + '/category/list'
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        if (page) URL = window.admin_base_URL + '/category/list?page=' + page
        let pagination = {};
        let data = [];
        await axios.get(URL).then(res => {
            data = res.data.result
            pagination = res.data.pagination

        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return { categories: data, pagination: pagination, currentPage: page };
        });
    };
    GoToEditForm = async event => {
        window.location.href = "categories/form?id=" + event.target.id
    }
    handleChange = event => {
        let search = event.target.value
        this.setState({search});
    }
    searchOnTitle = async  () =>{
        const URL = window.admin_base_URL +  "/category/search?fields=title&q=" + this.state.search
        let data = [];
        await axios.get(URL).then(res=>{
            data = res.data.result
        }).catch(err=>{
            console.error(err)
        })
        this.setState(() => {
            return {categories: data};
        });
    }


    render() {

        let { categories, pagination ,search} = this.state;

        return (
            <div className="ps-page pb-75">
                <BannderHead title={'لیست دسته بندی‌ها'} />
                <div className='ml-4 mb-4 d-flex'>
                    <div className="w-50 text-right">
                        <div className='mb-3'>
                            <span>تعداد کل: {pagination.count}</span>&nbsp;;&nbsp;
                            <span>تعداد نمایش در صفحه : {pagination.limit}</span>&nbsp;;&nbsp;
                            <span>شماره صفحه : {pagination.page}</span>
                        </div>

                        <div className='input-group w-auto rounded-pill no-print'>
                            <label htmlFor="serach" className='searchInputButton'>
                                <input type="text" className="form-control rounded-pill h-auto"
                                       placeholder="جستجو عنوان "
                                       onChange={this.handleChange}
                                       name='search'
                                       value={search}
                                       id='search'/>
                                <button className=" rounded-circle " type="button"
                                        onClick={this.searchOnTitle}
                                        id="button-addon1" style={{zIndex: 1}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                    </svg>
                                </button>
                            </label>


                        </div>
                    </div>

                    <div className="w-50">
                        <Pagination pagination={pagination} />
                        <a href='categories/form' className="ps-btn  ps-btn--sm"> ایجاد دسته بندی
                        </a>
                    </div>
                </div>
                <table className="table table-hover table-striped  text-right mb-4">
                    <thead>
                        <tr>
                            <th scope="col">عنوان</th>
                            <th scope="col">وضعیت</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((item, index) => {
                            return <tr key={index}>
                                <td>{item.title}</td>
                                <td>{item.enabled ? "فعال" : "غیر فعال"}</td>
                                <td>
                                    <button type="button" className="ps-btn ps-btn--outline ps-btn--sm"
                                        onClick={this.GoToEditForm} id={item._id}>ویرایش
                                    </button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <Pagination pagination={pagination} />
            </div>
        );
    }
}
