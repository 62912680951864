import React, { Component } from "react";
import $ from "jquery";
import BannderHead from "../components/layout/BannderHead";
import axios from "axios";
import MapInput from "../components/map/MapInput";
import { groupBy } from "lodash";
var moment = require('moment-jalaali')

function productMiniCart(product, count, index) {
    return <tr className='text-right' key={index}>
        <td>
            <div className="ps-product--cart">
                <div className="ps-product__thumbnail">

                    <a className="ps-product__overlay"
                        href={"/products/" + product._id}>
                        <img src={window.file_URL + product.img} alt="" />
                    </a>
                </div>
                <div className="ps-product__content">
                    <a className="ps-product__title"
                        href={"/products/" + product._id}>{product.title}</a>
                </div>
            </div>
        </td>
        <td>
            {count}
        </td>
        <td className="total">{(count * product.price).toLocaleString()}</td>
    </tr>
}
export default class profilePage extends Component {
    state = {
        client: {
            _id: null,
            name: '',
            address: '',
            phone: '',
            postCode: '',
            shopTitle: '',
            tel: '',
            location: [35.7219, 51.3347],
        },
        status: 0,
        orders: [],
        pagination: {},
        enabledEditOrder: false
    }

    async componentDidMount() {
        await this.getClient();
        await this.getOrders(1);
        var accordion = $('.ps-accordion');
        accordion.find('.ps-accordion__content').hide();
        $('.ps-accordion.active').find('.ps-accordion__content').show();
        accordion.find('.ps-accordion__header').on('click', function (e) {
            e.preventDefault();
            if ($(this).closest('.ps-accordion').hasClass('active')) {
                $(this).closest('.ps-accordion').removeClass('active');
                $(this).closest('.ps-accordion').find('.ps-accordion__content').slideUp(350);
            } else {
                $(this).closest('.ps-accordion').addClass('active');
                $(this).closest('.ps-accordion').find('.ps-accordion__content').slideDown(350);
                $(this).closest('.ps-accordion').siblings('.ps-accordion').find('.ps-accordion__content').slideUp();
            }
            $(this).closest('.ps-accordion').siblings('.ps-accordion').removeClass('active');
            $(this).closest('.ps-accordion').siblings('.ps-accordion').find('.ps-accordion__content').slideUp();
        });


    }

    handleChange = event => {
        this.setState({
            client: {
                ...this.state.client,
                [event.target.name]: event.target.value
            }
        });

    };
    getClient = async () => {
        let data = null;
        const client = JSON.parse(sessionStorage.getItem('client'));
        await axios.get(window.base_URL + '/client/read/' + client._id).then(res => {
            data = res.data.result;
        }).catch(err => {
            console.error(err)
        })
        if (data) {
            if (!data.location || data.location.length < 2) {
                data.location = [35.7219, 51.3347];
            }
            this.setState(() => {
                return { client: data };
            });
        }
    };
    getOrders = async (page) => {
        const client = this.state.client
        let URL = window.base_URL + '/order/myOrder/' + client._id
        if (page) {
            URL = window.base_URL + '/order/myOrder/' + client._id + "?page=" + page
        }
        await axios.get(URL)
            .then(async res => {
                let { result, pagination } = res.data
                await result.forEach((order) => {
                    order._products = order.products
                    order.products = groupBy(order.products, '_id');
                })
                this.setState(() => {
                    return { orders: result, pagination };
                });

            })
            .catch(err => {
                console.error(err)
            })
    };
    async logOut() {
        await axios.post(window.base_URL + '/logout')
            .then(res => {
                var now = new Date();
                document.cookie = "token=; expires=" + now.toUTCString() + "; path=/;";
                sessionStorage.removeItem('admin');
                sessionStorage.removeItem('client');
                sessionStorage.removeItem('order');
                sessionStorage.removeItem('token');
                window.location.href = '/'
            }).catch(err => {
                console.error(err)
            })
    }
    editClient = async event => {
        event.preventDefault();
        const { client } = this.state;
        let URL = window.base_URL + "/client/update/" + client._id
        await axios.patch(URL, client)
            .then(() => {
                this.setState({
                    client, status: 200
                });
            })
            .catch(err => {
                this.setState({
                    client, status: 400
                });
                console.error(err)
            })


    };
    onChangeLocation = (location) => {
        if (location) {

            this.setState({ client: { ...this.state.client, location } })
        }
    }
    prevPage = (event) => {
        event.preventDefault();
        let page = this.state.pagination.page
        if (page) {
            page--
        } else {
            page = 1
        }
        this.getOrders(page)

    }

    nextPage = (event) => {
        event.preventDefault();
        let page = this.state.pagination.page
        if (page) {
            page++
        } else {
            page = 2
        }
        this.getOrders(page)
    }
    reorder(products) {
        let order = {
            products: products
        }
        sessionStorage.setItem('order', JSON.stringify(order));
        alert('به سبد خرید اضافه شد')
        window.location.href = '/cart'
    }

    editorder(order) {
        let editOrder = {
            products: order._products
        }
        sessionStorage.setItem('order', JSON.stringify(editOrder));
        sessionStorage.setItem('editorder', order._id);
        alert('ویرایش سفارش تنها تا ساعت ۲۲:۰۰ هر روز میسر میباشد')
        window.location.href = '/cart';
    }

    changePage = event => {
        event.preventDefault();
        let page = event.target.value
        this.getOrders(page)
    }

    enabledEditOrder(order) {
        var pattern = new RegExp(moment(new Date()).format('jYYYY/jMM/jDD'));
        var d = new Date();
        let result = false;
        if (pattern.test(order.created)) {
            if (d.getHours() < 22 && d.getHours() >= 7) {
                result = true;
            } else {
                result = false;
            }
        }



        return result;
    }

    render() {
        const {
            _id,
            name,
            address,
            phone,
            postCode,
            shopTitle,
            tel,
            location
        } = this.state.client;
        const { status, orders, pagination, enabledEditOrder } = this.state;
        return (
            <div className="ps-page--profile">
                <BannderHead title={"پروفایل"} />
                <div className="container">
                    <div className="ps-profile">
                        <form className="ps-form--profile" onSubmit={this.editClient}>
                            <div className="row" dir='rtl'>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <label>نام / نام خانوادگی</label>
                                        <input
                                            required
                                            className="form-control shadow-sm "
                                            placeholder='نام / نام خانوادگی'
                                            type="text"
                                            name='name'
                                            value={name}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <label>کد پستی</label>
                                        <input
                                            required
                                            className="form-control shadow-sm "
                                            placeholder='نام فروشگاه'
                                            type="text"
                                            name='shopTitle'
                                            value={shopTitle}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <label>کد پستی</label>
                                        <input
                                            required
                                            className="form-control shadow-sm no-spinners"
                                            type="number" placeholder="کد پستی"
                                            name='postCode'
                                            value={postCode}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <label>شماره تلفن همراه</label>
                                        <input
                                            required
                                            className="form-control shadow-sm no-spinners"
                                            type="number" placeholder="شماره تماس همراه"
                                            name='phone'
                                            value={phone}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <label>شماره تماس ثابت</label>
                                        <input
                                            required
                                            className="form-control shadow-sm no-spinners"
                                            type="number" placeholder="شماره تماس ثابت"
                                            name='tel'
                                            value={tel}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <label>آدرس</label>
                                        <input
                                            className="form-control shadow-sm"
                                            type="text"
                                            placeholder='آدرس'
                                            required
                                            name='address'
                                            value={address}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    {_id ? <MapInput dragable location={location}
                                        onChangeLocation={this.onChangeLocation} /> : ''}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className="form-group ps-block__footer text-left pt-30">
                                    <button className=" rounded-pill btn btn-outline-danger ps-btn--sm " onClick={this.logOut}>خروج از حساب کاربری</button>
                                </div>
                                <div className="form-group ps-block__footer text-right pt-30">
                                    <button className="ps-btn ps-btn--sm" type='submit'>ویرایش مشخصات</button>
                                </div>

                            </div>

                            {status === 200 ?
                                <div className="alert alert-success text-right" role="alert">
                                    درخواست با موفقیت ثبت شد
                                </div> : ''
                            }
                            {status === 400 ?
                                <div className="alert alert-danger text-right" role="alert">
                                    خطا در ثبت اطلاعات رخ داده است.
                                </div> : ''
                            }
                        </form>
                    </div>
                    <h2 className="rtl text-right mb-20">سوابق سفارشات</h2>
                    <div className='accordion mb-100'>
                        {orders.map((order, index) => {
                            return <div className='mb-1 card ' key={index}>
                                <div className='ps-accordion  '>
                                    <div
                                        className="ps-accordion__header text-right card-header ">
                                        <div className="mb-0" dir='rtl'>{order.created}</div>
                                    </div>
                                    <div className="ps-accordion__content">
                                        <div className="card-body">
                                            <div className="" dir='rtl'>
                                                <div className="table-responsive">
                                                    <table className="table ps-table ps-table--shopping-cart">
                                                        <thead>
                                                            <tr className='text-right'>

                                                                <th>نام محصول</th>

                                                                <th>تعداد</th>
                                                                <th>قیمت کل</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.entries(order.products)
                                                                .sort((a, b) => a[0] > b[0] ? 1 : -1)
                                                                .map((item, index) => {
                                                                    return productMiniCart(item[1][0], item[1].length, index)
                                                                })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="ps-section__actions rtl text-right">
                                                    <figure>
                                                        <button className="ps-btn--sm btn-outline-dark btn-outline ml-10 rounded-pill" onClick={() => this.reorder(order._products)} >سفارش مجدد</button>
                                                        {this.enabledEditOrder(order) ?
                                                            <button className="ps-btn--sm btn-outline-dark btn-outline ml-10 rounded-pill" onClick={() => this.editorder(order)} >ویرایش سفارش</button> : ''}
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="ps-pagination mb-70">
                        <ul className="pagination">
                            {pagination.page != 1 ?
                                <li><a href="/" onClick={this.prevPage}><i className="fa fa-caret-left"></i></a>
                                </li> : ""}
                            {Array.from(Array(pagination.pages), (e, item) => {
                                return <li className={`${pagination.page == (item + 1) ? 'active' : ''}`} style={{ 'cursor': 'pointer' }}
                                    key={item + 1}><button className=' ' onClick={this.changePage} value={item + 1} >{item + 1}</button></li>
                            })}
                            {pagination.page != pagination.pages ?
                                <li><a href="/" onClick={this.nextPage}><i className="fa fa-caret-right"></i></a>
                                </li> : ''}
                        </ul>
                    </div>

                </div>
            </div>
        );
    }
}
