import React, {Component} from "react";
import BlogCard from "./../BlogCard";
import axios from "axios";

export default class LastBlogs extends Component {
    state = {
        blogs:[],
    }
    async componentDidMount() {

        await this.getBlogs();
    }
    getBlogs = async () => {
        let data = [];
        await axios.get(window.base_URL + '/blog/list?items=3').then(res => {
            data = res.data.result
        }).catch(err=>{
            console.error(err)
        })
        this.setState(() => {
            return {blogs: data};
        });
    };
    render() {
        let {blogs} = this.state
        return (
            <div className="ps-section ps-home-blog">
                <div className="container">
                    <div className="ps-section__header">
                    <i className="chikery-tt2"></i>
                    <h3>وبلاگ</h3>
                    </div>
                    <div className="ps-section__content">
                        <div className="row">
                            {blogs.map((blog,index)=>{
                                return <div key={index} className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                                    <BlogCard blog={blog}/>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="ps-section__footer"><a className="ps-btn ps-btn--outline" href="/blogs">مشاهده بیشتر</a>
                    </div>
                </div>
            </div>
        );
    }
}

