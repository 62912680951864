import React, { Component } from "react";
import Product from "./Product";
import { PropTypes } from "prop-types";
import ButtonCount from "./ButtonCount";

export default class ProductCard extends Component {
    render() {
        const { _id, img, title, price, weight, timeToCook } = this.props.product;
        if (this.props.product) return (
            <div className="ps-product">
                <div className="ps-product__thumbnail">
                    
                    <a className="ps-product__overlay" href={"/products/" + _id} >
                    <img src={window.file_URL + img} alt="" />
                    </a>

                </div>
                <div className="ps-product__content">
                    <div className="ps-product__desc">
                        <a className="ps-product__title"
                            href={"/products/" + _id}>{title}</a>
                        <p>
                            {weight ? <span>{weight}&nbsp;<sub>گرم</sub>&nbsp;</span> : ''}
                            {timeToCook ? <span>{timeToCook}&nbsp;<sub>ساعت</sub>&nbsp;</span> : ''}
                        </p>
                        <span className="ps-product__price">{price.toLocaleString()} <sub>تومان</sub></span>
                        <div className='pr-3 pl-3 d-md-none'>
                            <ButtonCount id={this.props.product._id} product={this.props.product} />
                        </div>
                    </div>
                    <div className="ps-product__shopping pr-40 pl-40" style={{ maxWidth: 220 }}>
                        <ButtonCount id={this.props.product._id} product={this.props.product} />
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    product: PropTypes.shape({
        _id: PropTypes.string,
        img: PropTypes.string,
        title: PropTypes.string,
        price: PropTypes.string,
        weight: PropTypes.string,
        timeToCook: PropTypes.string,
    }).isRequired
};
