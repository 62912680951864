import React, {Component} from "react";
import BannderHead from "../components/layout/BannderHead";
import axios from "axios";

export default class OpenJobsPage extends Component {
    state = {
        jobs:[]
    }
    async componentDidMount() {
        await this.getJobs();
    }

    getJobs = async () => {
        let tempJobs = [];
        await axios.get(window.base_URL + '/job/list').then(res => {
            tempJobs = res.data.result
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return {jobs: tempJobs};
        });
    };

    render() {
        let temp = this.state.jobs;
        return (
            <div className="ps-page open-jobs mb-100" >
                <BannderHead title={'فرصت‌های شغلی'}/>
                <div className="ps-section ps-section--good-baker">
                    <div className="container">
                        <div className="row" dir='rtl'>
                            {temp.map(i=>{
                                return <div className="col-12 col-md-6 mb-md-3  text-right">
                                    <div className="ps-section__header text-right">
                                        <h3>{i.title}</h3>
                                    </div>
                                    <div className="ps-section__content">
                                        <p className=''>{i.description}</p>
                                        <a className="ps-btn ps-btn--sm" href={i.link}>ارسال درخواست</a>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
