import React, { Component } from "react";

// import NavigationMobile from "./NavigationMobile";
import $ from "jquery";
import { groupBy, orderBy } from "lodash";
import ButtonCount from "../Product/ButtonCount";



export default class Navbar extends Component {
    state = {
        result: {},
        order: {},
        totalPrice: 0,
        isLoggedIn: false,
        client: null
    }
    constructor(props) {
        super(props);

        const client = JSON.parse(sessionStorage.getItem('client'));
        if (client) {
            this.state.isLoggedIn = true;
            this.state.client = client;
        }


        this.state.order = JSON.parse(sessionStorage.getItem('order')) || {}
        let total = 0;
        if (this.state.order.products && this.state.order.products.length > 1) {
            this.state.order.products.forEach(item => {
                total += parseInt(item.price)
            });
            this.state.result = groupBy(orderBy(this.state.order.products, "_id"), ({ _id }) => _id);
        }
        this.state.totalPrice = total;

    }


    // productMiniCart = (product, count, index) => {
    //     return <div className="ps-product--mini-cart" key={index}>
    //         <div className="ps-product__thumbnail"><a href={"/products/" + product._id}><img
    //             src={window.file_URL + product.img}
    //             alt="" /></a></div>
    //         <div className="ps-product__content">
    //             {/* <span className="ps-btn--close"></span> */}
    //             <a className="ps-product__title" href={"/products/" + product._id}>{product ? product.title : ""}</a>
    //             <div>
    //                 <div className='d-flex align-items-center'>
    //                     {product ? <ButtonCount id={product._id} product={product} /> : ''}
    //                     <strong className='ml-4'>تعداد</strong>
    //                 </div>
    //                 <div>
    //                     <strong> قیمت </strong>
    //                     <small>{(count * product.price).toLocaleString()}</small>
    //                 </div>

    //             </div>
    //         </div>
    //     </div>
    // }

    componentDidMount() {

        window.addEventListener(
            "orderChange",
            (e) => {

                let order = JSON.parse(sessionStorage.getItem('order')) || {};


                let total = 0;
                if (order.products && order.products.length >= 0) {
                    order.products.forEach(item => {
                        total += parseInt(item.price)
                    })
                    this.setState({
                        order: order,
                        result: groupBy(orderBy(order.products, '_id'), '_id'),
                        totalPrice: total
                    });

                }

            },
            false,
        );

        $('.ps-toggle--sidebar').on('click', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            $(this).toggleClass('active');
            $(this).siblings('a').removeClass('active');
            $(url).toggleClass('active');
            $(url).siblings('.ps-panel--sidebar').removeClass('active');
            $('.ps-site-overlay').toggleClass('active');
        });

        $('body').on("click", function (e) {
            if ($(e.target).siblings(".ps-panel--sidebar").hasClass('active')) {
                $('.ps-panel--sidebar').removeClass('active');
                $('.ps-site-overlay').removeClass('active');
            }
        });

        $('.menu--mobile .menu-item-has-children > .sub-toggle').on('click', function (e) {
            e.preventDefault();
            var current = $(this).parent('.menu-item-has-children')
            $(this).toggleClass('active');
            current.siblings().find('.sub-toggle').removeClass('active');
            current.children('.sub-menu').slideToggle(350);
            current.siblings().find('.sub-menu').slideUp(350);
            if (current.hasClass('has-mega-menu')) {
                current.children('.mega-menu').slideToggle(350);
                current.siblings('.has-mega-menu').find('.mega-menu').slideUp(350);
            }

        });

        $('.menu--mobile .has-mega-menu .mega-menu__column .sub-toggle').on('click', function (e) {
            e.preventDefault();
            var current = $(this).closest('.mega-menu__column')
            $(this).toggleClass('active');
            current.siblings().find('.sub-toggle').removeClass('active');
            current.children('.mega-menu__list').slideToggle(350);
            current.siblings().find('.mega-menu__list').slideUp(350);
        });

        var header = $('.header'),
            checkpoint = 50;
        if (header.data('sticky') === true) {
            $(window).scroll(function () {
                var currentPosition = $(this).scrollTop();
                if (currentPosition > checkpoint) {
                    header.addClass('header--sticky');
                } else {
                    header.removeClass('header--sticky');
                }
            });
        } else {
            return false;
        }
        var stickyCart = $('#cart-sticky');
        if (stickyCart.length > 0) {
            $(window).scroll(function () {
                var currentPosition = $(this).scrollTop();
                if (currentPosition > checkpoint) {
                    stickyCart.addClass('active');
                } else {
                    stickyCart.removeClass('active');
                }
            });
        }


        var listCategories = $('.ps-list--categories');
        if (listCategories.length > 0) {
            $('.ps-list--categories .menu-item-has-children > .sub-toggle').on('click', function (e) {

                e.preventDefault();
                var current = $(this).parent('.menu-item-has-children')
                $(this).toggleClass('active');
                current.siblings().find('.sub-toggle').removeClass('active');
                current.children('.sub-menu').slideToggle(350);
                current.siblings().find('.sub-menu').slideUp(350);
                if (current.hasClass('has-mega-menu')) {
                    current.children('.mega-menu').slideToggle(350);
                    current.siblings('.has-mega-menu').find('.mega-menu').slideUp(350);
                }

            });
        }

    }

    render() {

        const { result, client } = this.state;

        return (
            <div>
                <header className="header header--default header--home-4" data-sticky="true">
                    <div className="header__left">
                        <ul className="ps-list--social">
                            <li><a href={this.props.instagram}><i className="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div className="header__center">
                        <nav className="header__navigation left">
                            <ul className="menu">
                                <li>
                                    <a href="/contactUs">تماس با ما</a>
                                </li>
                                <li className="">
                                    <a href="/blogs">وبلاگ</a>
                                </li>
                                <li className="">
                                    <a href="/joinUs">مشتری جدید</a>
                                </li>
                            </ul>
                        </nav>
                        <div className="header__logo">
                            <a className="ps-logo" href="/">
                                <img src={window.site_URL + this.props.logo} alt={this.props.title} />
                            </a>
                        </div>
                        <nav className="header__navigation right">
                            <ul className="menu">
                                <li className="menu-item-has-children">
                                    <a href="/branches">شعب</a>
                                </li>
                                <li className="menu-item-has-children">
                                    <a href="/products">محصولات</a>
                                </li>
                                <li className="current-menu-item">
                                    <a href="/">خانه</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="header__right">
                        <div className="header__actions">

                            <a href={this.state.isLoggedIn ? "/profile" : "/login"}>
                                {this.state.isLoggedIn ? <i className="mr-3">{client.name}</i> : ''}
                                <i className="fa fa-user"></i>

                            </a>
                            <div className="ps-cart--mini">
                                <a className="ps-cart__toggle" href="/cart">
                                    <i className="fa fa-shopping-basket"></i>
                                    {Object.entries(result).length > 0 ? <span></span> : ''}
                                </a>
                                {/* <div className="ps-cart__content">
                                    <div className="ps-cart__items">
                                        {Object.entries(result).map((item, index) => {

                                            return this.productMiniCart(item[1][0], item[1].length, index)

                                        })}

                                    </div>
                                    <div className="ps-cart__footer">
                                        <div className='d-flex justify-content-between'>
                                            <h3>
                                                <strong>{this.state.totalPrice.toLocaleString()}</strong>
                                            </h3>
                                            <h3 dir='rtl'>
                                                مجموع:
                                            </h3>
                                        </div>

                                        <figure>
                                            <a className="ps-btn w-100" href="/cart">مشاهده سبد</a>
                                        </figure>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </header>
                <header className="header header--mobile" data-sticky="true">
                    <div className="header__content">
                        <div className="header__left justify-content-start">
                            <div className="header__actions"><a href="/cart"><i
                                className="fa fa-shopping-basket"></i>
                                {Object.entries(result).length > 0 ? <span></span> : ''}</a>
                            </div>
                        </div>
                        <div className="header__center">
                            <a className="ps-logo" href="/">
                                <img src={window.site_URL + this.props.logo} alt="" />
                            </a>
                        </div>
                        <div className="header__right justify-content-end">
                            <div className=' header__actions'>
                                <a className='' href={this.state.isLoggedIn ? "/profile" : "/login"}>
                                    {this.state.isLoggedIn ? <i className="mr-3">{client.name}</i> : ''}
                                    <i className="fa fa-user"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="navigation--list ">
                    <div className="navigation__content" dir="rtl">
                        <a className="navigation__item text-white" href="/">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                className="bi bi-house-door" viewBox="0 0 16 16">
                                <path
                                    d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                            </svg>
                            <span>خانه</span>
                        </a>
                        <a
                            className="navigation__item text-white"
                            href="/products">
                            <svg width='32' height={32} fill='#ffffff'
                                viewBox="0 0 522 522" enableBackground="new 0 0 522 522">
                                <path d="M39.5,457l-0.5-0.1c-15.2-3.7-25.2-13.6-30.6-30.4c-1.2-3.9-2.9-7.6-4.7-11.5c-0.8-1.8-1.6-3.6-2.4-5.5L1,408.8v-7l0.6-1
	c2.9-4.7,7.1-7.1,12.1-7.1c0.7,0,1.5,0.1,2.3,0.2c1.6,0.2,3,0.3,4.2,0.3c4.6,0,8.1-1.4,11.2-8.6c2.8-6.7,7.9-12.2,12.9-17.5
	c2.1-2.2,4-4.3,5.8-6.5c1.5-1.8,3.1-3.6,4.6-5.3c0.1-0.1,0.2-0.2,0.3-0.3l-0.6-1.1c-5.6-10.2-3.6-20.6,5.4-27.8
	c17-13.5,35.4-22.7,54.8-27.1c3.3-0.8,3.9-1.2,4.5-3.1c2-7.2,8.3-10.6,12.9-12.5c17-7.2,34.7-10.8,52.6-10.8
	c10.2,0,20.6,1.2,31.1,3.5l-2-26.4c-0.3-3.5-0.6-7.1-0.9-10.6c-0.6-7.8-1.3-15.9-1.7-23.9c-0.4-9.2,2.1-17,7.3-22.5
	c5.2-5.5,12.8-8.4,21.9-8.4c31.8-0.1,63.4-0.1,93.8-0.1c31.1,0,62.1,0,92.1,0.1c9.2,0,16.8,3,22.1,8.7c5.3,5.8,7.8,13.9,7,23.4
	c-0.1,0.7-0.1,1.3-0.1,1.9c0.9-0.1,1.8-0.2,2.6-0.3c4.3-0.5,8.8-1,13.2-1c1,0,2,0,2.9,0.1c22.9,1.3,42.7,19.5,46.2,42.5
	c0,0.3,0.2,0.7,0.4,1.2c0.1,0.3,0.2,0.7,0.3,1l0.2,0.6v27.3l-0.2,0.6c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.2,0.6-0.3,0.7
	c-4,25.2-25.2,43.4-50.4,43.4c-4.1,0-8.2-0.5-12.3-1.5c-3.8-0.9-7.4-2.4-10.9-3.8c-0.1,0.9-0.1,1.7-0.2,2.6
	c-0.5,6.2-0.9,12.3-1.4,18.4c-0.2,2.6-0.4,5.1-0.6,7.7c-0.5,6.9-1,14-2.2,21c-0.8,4.6-2.6,8.9-4.3,13c0,0,0,0,0,0
	c5.8,0,11.5,0,17.3,0c6,0,12,0,18,0c13.2,0,23.3,0,32.8-0.1l0.2,0c4.2,0,10.3,0.7,14.1,7.2l0.6,1v6.9l-0.3,0.7c-1,2.4-2,4.8-3,7.1
	c-2.3,5.4-4.7,11-6.9,16.5c-3.6,8.7-8.6,14.8-15.5,18.5c-2.7,1.5-5.5,2.5-8.3,3.6c-1.2,0.4-2.3,0.9-3.5,1.4l-0.7,0.3H39.5z
	 M29.7,417c0.5,1.4,1.1,2.7,1.6,4c4.5,11.1,6.9,12.8,18.8,12.8l421.4,0c0.5,0,1,0,1.6,0c0.5,0,1,0,1.5,0c0.9,0,1.5,0,2.1-0.1
	c7-0.5,9.2-3.2,10.3-5.2c2-3.6,3.6-7.4,5.3-11.5c0-0.1,0.1-0.1,0.1-0.2l-33.6,0c-19.1,0-38.2,0-57.2,0c-2.3,0-4.5,0-6.8-0.6
	c-5.8-1.3-9.2-5.9-8.8-11.8c0.4-5.8,4.1-9.8,10.1-10.6c1.1-0.2,2.2-0.2,3.2-0.3c1-0.1,1.9-0.1,2.8-0.3c10.3-1.5,17.3-8.8,18.1-19.1
	c3.9-50.2,7.9-101.6,12.1-156.9c0.3-4.2,0.1-6.7-0.8-7.6c-1.3-1.4-6.2-1.4-7.9-1.4c-60.3,0-120.7,0-181,0.1c-4.2,0-6.6,0.5-7.5,1.4
	c-0.9,0.9-1.2,3.5-0.9,7.5c1.5,21.5,3.3,43.9,5.2,66.6c0.2,0.5,1.1,1.5,1.6,2l0.3,0.3c0.8,0.9,1.8,1.8,2.8,2.7
	c2.2,2,4.7,4.2,6.1,7.3c1.1,2.5,2,3.1,4.2,3.6c19.3,4.3,37.7,13.3,54.5,26.6c9.9,7.8,12,18,5.9,28.9c-0.2,0.3-0.3,0.5-0.3,0.7
	c0.1,0.1,0.3,0.4,0.6,0.8c0.9,1,1.8,2,2.7,3c4.5,4.9,9.1,10,13.3,15.4c3.8,5,7.3,10.7,10.5,17.1c0.8,1.7,0.9,1.7,1.9,1.7l0.2,0
	c2.9-0.1,5.9-0.1,9.2-0.1c4.6,0,9.1,0.1,12.5,0.2c6.1,0.1,10.3,4,11.2,10.2c0.8,5.9-3.5,11.4-9.9,12.5c-1.6,0.3-3.2,0.3-4.6,0.3
	l-2,0c-101.6,0-203.1,0-304.7,0.1L29.7,417z M151.1,393.7c0.2,0,0.7,0.1,1.4,0.1l0.2,0c9.1-0.1,18.7-0.1,31.3-0.1
	c5.4,0,10.9,0,16.3,0c5.4,0,10.9,0,16.3,0h1.4l0.2-1.2c0.4-3.2,0.8-6.2,1.2-9.2l1.4-11.3c2.6-21.4,5.3-43.5,7.7-65.2
	c-0.1-0.3-0.4-0.8-0.6-1c-14.4-6-29-9-43.4-9c-14.3,0-28.8,3-43.1,8.8c-0.4,0.2-0.7,0.3-0.8,0.4c0,0.1,0,0.2,0.1,0.4
	c0.6,3.2,1,6.8,1.3,10.9c0.5,6.7-3.2,11.8-9.3,13.1c-0.9,0.2-1.8,0.3-2.6,0.3c-4.8,0-8.6-2.9-10.6-7.9c-16.1,4-30.4,11.1-43.7,21.7
	c0,0.3,0,0.7,0.1,0.9c9.2,14.1,19.2,29.1,31.4,47.2c0.3,0.4,0.9,0.9,1.2,1c3.1,0.1,6.5,0.2,10.9,0.2c2,0,4,0,6,0c0.7,0,1.4,0,2.1,0
	c0-0.4-0.1-0.7-0.1-1.1c-0.4-3.8-0.9-7.5-1.4-11.3c-1.1-8.8-2.2-17.9-3.1-26.9c-0.5-5,1.1-8.1,2.6-9.8c1.9-2.3,4.6-3.6,7.9-3.8
	c0.3,0,0.7,0,1,0c6.3,0,10.8,4.4,11.7,11.4c0.4,2.8,0.7,5.6,1.1,8.4c1.3,10.4,2.7,21.2,3.7,31.9C151,393,151,393.4,151.1,393.7z
	 M243.7,393.6c2,0,4,0,5.9,0c3.2,0,7.4,0,11.7-0.1c0.2-0.2,0.5-0.5,0.7-0.8c5.3-7.9,10.6-15.8,15.9-23.7
	c5.4-8.1,10.9-16.3,16.3-24.4c-1.9-1.2-3.7-2.5-5.5-3.7c-3.9-2.7-7.6-5.3-11.6-7.5c-8.4-4.8-17.2-8.3-26.7-10.5l-8.5,70.7
	C242.5,393.6,243.1,393.6,243.7,393.6z M79.3,393.2l-11.2-16.9l-14.2,16.9H79.3z M315.4,393.2l-14.1-16.8l-11.2,16.8H315.4z
	 M453.2,246.8c-1.8,19.6-3.2,39.4-4.3,56.1c0,0.6,0.4,1.9,1,2.5c5,5.1,12,8,19.5,8c0,0,0,0,0,0c8.1,0,15.8-3.3,21-9.2
	c5.9-6.6,8.2-14.6,7.8-27c-0.1-1.5-0.2-3-0.2-4.5c-0.1-3.1-0.2-6.1-0.7-9c-2.4-13.5-14.1-23.3-27.9-23.3c-4.7,0-9.1,1.2-13.2,3.4
	C454.8,244.7,453.4,246.2,453.2,246.8z"/>
                                <path d="M325.5,176.1c-0.7,0-1.4-0.1-2.1-0.2c-3.4-0.6-6-2.2-7.7-4.6c-1.6-2.3-2.2-5.2-1.6-8.3c0.9-4.9,2.8-9.5,4.5-13.4
	c1-2.3,2.7-3.9,4.1-5.3c0.3-0.3,0.6-0.6,0.8-0.8c2.4-2.4,6.4-6.4,6.4-8.1c0-1.7-3.9-5.6-6.3-8c-6.6-6.6-10-14-10-21.5
	c0-7.5,3.6-14.9,10.2-21.5c3.8-3.8,6.2-6.4,6.7-10.5c0.3-2.9,2.5-4.8,4.2-6.3c0.6-0.5,1.1-1,1.4-1.3l1.2-1.3h7l1,0.7
	c7.8,5.3,9.7,12.2,5.4,20.5c-2.9,5.7-7.3,10.7-10.8,14.7c-2.3,2.6-3.4,4.5-3.3,5.7c0.1,1.3,1.6,2.8,2.9,3.9
	c8.6,7.4,13.2,16.1,13.1,25.2c0,8.9-4.5,17.4-12.9,24.5c-1.5,1.3-2.6,4.7-3.3,6.7C333.7,174.9,328.4,176.1,325.5,176.1z"/>
                                <path d="M368.5,168.2c-1.5,0-3.1-0.3-4.6-1c-6.4-2.6-8.5-9.2-5.5-16.7c5.3-13,4.2-24.8-3.4-36.1c-3.6-5.3-3.7-10.6-0.3-16.6
	c2.1-3.8,3.7-7.9,5.3-12.2c0.5-1.4,1-2.7,1.6-4.1c2.7-6.8,7.3-8.3,10.7-8.3c0.6,0,1.2,0,1.9,0.1c3.9,0.6,8.9,3,10.5,11.1l0.2,1.1
	l-0.4,1.1c-0.6,1.7-1.2,3.3-1.8,5c-1.5,4.1-3,8.3-4.9,12.4c-0.8,1.7-0.7,2.4,0,3.8c8.5,16.7,9.1,34,1.7,51.5
	C376.4,167.1,371.3,168.2,368.5,168.2z"/>
                                <path d="M303.1,146.8c-3.8,0-7.3-2-10-5.7c-7.6-10.5-11.6-22.5-11.8-35.5l0-0.3l0-0.3c0.2-1.3,0.4-2.7,0.5-4
	c0.4-3.1,0.8-6.3,1.5-9.5c1.3-6.2,5.8-10.3,11.4-10.3c0.7,0,1.4,0.1,2,0.2c3.2,0.6,5.8,2.3,7.5,4.7c1.8,2.6,2.3,5.9,1.6,9.4
	c-2.6,12.4-0.5,23.2,6.3,33c2,2.8,2.7,6.1,2.1,9.2c-0.6,3.1-2.6,5.7-5.4,7.4C307,146.3,305.1,146.8,303.1,146.8z"/>
                            </svg>
                            <span>محصولات</span>
                        </a>
                        <a
                            className="navigation__item  text-white"
                            href="/branches">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                className="bi bi-shop-window" viewBox="0 0 16 16">
                                <path
                                    d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5m2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5" />
                            </svg>
                            <span>شعبه ها</span>
                        </a>
                        <a className="navigation__item text-white" href="/joinUs">
                            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={42} fill="#ffffff" className="bi bi-person-plus" viewBox="0 0 16 16">
                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                            </svg>
                            <span>مشتری جدید</span>

                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
