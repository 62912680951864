import React, { Component } from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";
import FileUpload from '../../components/FileUpload';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


export default class AdminBlogsFormPage extends Component {

    state = {
        blog: {
            enabled: '',
            title: '',
            img: '',
            shortDescription: '',
            description: '',
            tags: '',
            created: '',
        },
        status: 0,
        editorState: EditorState.createEmpty(),
    }
    onEditorStateChange: Function = (editorState) => {
        let description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.setState({
            editorState,
            blog: {
                ...this.state.blog,
                description: description
            }
        });
    };

    async componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            await this.getBlog();
        }
        else {
            const html = '<p>توضیحات</p>';
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({editorState})
            }
        }
    }
    uploadedFile = (path) => {
        this.setState({ blog: { ...this.state.blog, img: path } })
    }
    getBlog = async () => {
        let data = [];
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');

        await axios.get(window.admin_base_URL + '/blog/read/' + myParam)
            .then(res => {
                data = res.data.result
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return { blog: data };
        });
        const html = data.description;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({editorState})
        }
    }
    handleChange = event => {
        this.setState({
            blog: {
                ...this.state.blog,
                [event.target.name]: event.target.value
            }
        });
    };


    createBlog = async event => {
        event.preventDefault();
        const { blog } = this.state;
        let URL = window.admin_base_URL + "/blog/create"
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            URL = window.admin_base_URL + "/blog/update/" + myParam
            await axios.patch(URL, blog)
                .then(async () => {
                    await this.setState({
                        blog, status: 200
                    });
                    window.location.href = "/adminPanel/blogs"
                })
                .catch(async err => {
                    await this.setState({
                        blog, status: 400
                    });
                    console.error(err)
                })
        } else {
            await axios.post(URL, blog)
                .then(async () => {

                    await this.setState({
                        blog, status: 200
                    });
                    window.location.href = "/adminPanel/blogs"
                })
                .catch(async err => {
                    await this.setState({
                        blog, status: 400
                    });
                    console.error(err)
                })
        }
    };
    onChange = description => {
        this.setState({
            blog: {
                ...this.state.blog,
                description: description
            }
        });
    }


    render() {
        const { editorState } = this.state;
        const {
            enabled,
            title,
            img,
            shortDescription,
            tags,
        } = this.state.blog;
        const { status } = this.state;
        function uploadCallback(file) {
            return new Promise(async (resolve, reject) => {
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    await axios.post(window.admin_base_URL + "/file/upload", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                        resolve({ data: { link: window.file_URL+res.data.path } });
                    }).catch(err => {
                        console.error(err);
                    })
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            });
        }
        return (
            <div id='AdminBlogForm'>
                <BannderHead title={"فرم ایجاد/ویرایش وبلاگ"}/>
                <form className="ps-form--adminPanel" onSubmit={this.createBlog}>
                    <div className="row" dir='rtl'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='عنوان وبلاگ'
                                    type="text"
                                    name='title'
                                    required
                                    value={title || ""}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm"
                                    type="text"
                                    placeholder='توضیح کوتاه'
                                    required
                                    name='shortDescription'
                                    value={shortDescription}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm"
                                    type="text"
                                    placeholder="کلمات کلیدی"
                                    name='tags'
                                    value={tags}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className="form-group">
                                <FileUpload onUploadFile={this.uploadedFile} value={img} name='img'/>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className="form-group">
                                <select className=" form-control shadow-sm" value={enabled}
                                        name='enabled'
                                        onChange={this.handleChange}>
                                    <option value="" disabled>وضعیت </option>
                                    <option value={true}>فعال</option>
                                    <option value={false}>غیرفعال</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-12' dir='ltr'>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="mb-0 toolbarClassName shadow-sm"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName bg-white border"
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                    image: { previewImage: true, uploadEnabled: true, uploadCallback: uploadCallback, inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg' }
                                }}
                            />
                        </div>

                        <div className='col-12 my-4'>
                            {status === 200 ?
                                <div className="alert alert-success text-right" role="alert">
                                    درخواست با موفقیت ثبت شد
                                </div> : ''
                            }
                            {status === 400 ?
                                <div className="alert alert-danger text-right" role="alert">
                                    خطا در ثبت اطلاعات رخ داده است.
                                </div> : ''
                            }
                            <button className="ps-btn  ps-btn--sm " type="submit">ثبت
                                مشخصات
                            </button>
                        </div>

                    </div>


                </form>
            </div>
        );
    }
}
