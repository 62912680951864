import React, {Component} from "react";

export default class InfoBanner extends Component {
    render() {
        return (
            <div className="ps-section__right">
                
                <div className="ps-section__content">
                    <div className="row">
                        <div className="col-4">
                            <div className="ps-block--award">
                                <div className="ps-block__header"><img src="/img/icon/1.svg" alt=""/>
                                    <h4>لغو سفارش</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="ps-block--award">
                                <div className="ps-block__header"><img src="/img/icon/1.svg" alt=""/>
                                    <h4>ارسال مناسب</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="ps-block--award">
                                <div className="ps-block__header"><img src="/img/icon/1.svg" alt=""/>
                                    <h4>گواهی برند برتر سال</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

