import React, {Component} from "react";
import Product from "./Product/Product";
import {PropTypes} from "prop-types";

export default class BlogCard extends Component {
    render() {
        const {img, _id, shortDescription, title} = this.props.blog;
        return (
            <div className="ps-post text-right" dir='rtl'>
                <div className="ps-post__thumbnail">
                    <img src={window.file_URL + img} alt=""/>
                    <a className="ps-post__overlay" href={"/blogs/" + _id}>
                    </a>
                </div>
                <div className="ps-post__content">
                    {/*<p className="ps-post__meta">{date}, {year} </p>*/}
                    <a className="ps-post__title" href={"/blogs/" + _id}>{title}</a>
                    <p>{shortDescription}</p>
                    <a className="ps-post__morelink" href={"/blogs/" + _id}>بیشتر</a>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    blog: PropTypes.shape({
        img: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.string,
        shortDescription: PropTypes.string,
    }).isRequired
};
