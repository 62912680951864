import React, { Component } from "react";


export default class Footer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {


    }

    render() {
        let checkAdminPanel = window.location.pathname.includes("adminPanel") || window.location.pathname.includes("adminpanel")
        return (
            <footer className={checkAdminPanel ? "ps-footer--dark" : "ps-footer ps-footer--dark"}>
                {checkAdminPanel ? "" : <div className="ps-footer__content">
                    <div className="container">

                        <div className="ps-footer__left text-center">
                            <div className='ps-footer__left__items'>
                                <div><a href="/openJobs">فرصت‌های شغلی</a></div>
                                <div><a href="/branches">شعب</a></div>
                                <div><a href="/contactUs">تماس با ما</a></div>

                            </div>
                        </div>
                        <div className="ps-footer__center">
                            <a className="ps-logo d-flex justify-content-center " href="/">
                                <img className='mb-10' src={window.site_URL + this.props.logo} alt={this.props.title} />
                            </a>

                        </div>
                        <div className="ps-footer__right">
                            <div className="ps-site-info ">
                                {/* <p>{this.props.address}</p> */}
                                <p>
                                    <a href={this.props.instagram}><i className="fa fa-instagram"></i> instagram</a>
                                </p>
                                <p>
                                    <span>شماره تماس:</span>
                                    <span className="ps-hightlight"> {this.props.phone}</span>
                                </p>
                                <p>
                                    <a href="/joinUs">مشتری جدید</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="ps-footer__bottom">
                    <div className="container">
                        <p>© 1403 - {this.props.title}. طراحی، توسعه و پشتیبانی فنی وبسایت توسط <a href="https://socialinstance.ir">آژانس دیجیتال نمونه اجتماعی</a><span className="our-site">https://socialinstance.ir</span></p>
                    </div>
                </div>
            </footer>
        );
    }
}
