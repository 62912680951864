import React, { Component } from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";
import FileUpload from "../../components/FileUpload";


export default class AdminCategoryFormPage extends Component {
    state = {
        category: {
            enabled: '',
            title: '',
            icon: '',
        },
        status: 0
    }

    async componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            await this.getCategory();
        }
    }
    getCategory = async () => {
        let category = [];

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');

        await axios.get(window.admin_base_URL + '/category/read/' + myParam).then(res => {
            category = res.data.result
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return { category: category };
        });
    };
    uploadedFile = (path) => {
        this.setState({ category: { ...this.state.category, icon: path } })
    }
    handleChange = event => {
        this.setState({
            category: {
                ...this.state.category,
                [event.target.name]: event.target.value
            }
        });

    };
    createCategory = async event => {
        event.preventDefault();
        const { category } = this.state;
        let URL = window.admin_base_URL + "/category/create"
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            URL = window.admin_base_URL + "/category/update/" + myParam
            await axios.patch(URL, category)
                .then(() => {
                    this.setState({
                        category, status: 200
                    });
                    window.location.href = "/adminPanel/categories"
                })
                .catch(async err => {
                    await this.setState({
                        category, status: 400
                    });
                    console.error(err)
                })
        } else {
            await axios.post(URL, category)
                .then(() => {
                    this.setState({
                        category, status: 200
                    });
                    window.location.href = "/adminPanel/categories"
                })
                .catch(async err => {
                    await this.setState({
                        category, status: 400
                    });
                    console.error(err)
                })
        }

    };

    render() {

        const {
            title,
            enabled,
            icon
        } = this.state.category;
        const { status } = this.state;
        return (
            <div id='AdminCategoryForm'>
                <BannderHead title={'فرم ایجاد/ویرایش دسته بندی‌ها'} />
                <form className="ps-form--adminPanel" onSubmit={this.createCategory}>
                    <div className="row justify-content-center ps-form__content" dir='rtl'>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='عنوان دسته بندی'
                                    type="text"
                                    name='title'
                                    required
                                    value={title || ""}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <FileUpload onUploadFile={this.uploadedFile} value={icon} name='img' />
                            </div>

                            <div className="form-group">
                                <select className=" form-control shadow-sm" value={enabled} name='enabled'
                                    onChange={this.handleChange}>
                                    <option value='' className='d-none'>وضعیت</option>
                                    <option value={true}>فعال</option>
                                    <option value={false}>غیر فعال</option>
                                </select>
                            </div>
                            {status === 200 ?
                                <div className="alert alert-success text-right" role="alert">
                                    درخواست با موفقیت ثبت شد
                                </div> : ''
                            }
                            {status === 400 ?
                                <div className="alert alert-danger text-right" role="alert">
                                    خطا در ثبت اطلاعات رخ داده است.
                                </div> : ''
                            }
                            <div className="form-group ">
                                <button className="ps-btn  ps-btn--sm mr-auto" type="submit">ثبت
                                    مشخصات
                                </button>
                            </div>
                        </div>

                    </div>

                </form>
            </div>
        );
    }
}
