import React, {Component} from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";
import FileUpload from '../../components/FileUpload';

export default class AdminProductsFormPage extends Component {

    state = {
        categories: [],
        product: {
            enabled: '',
            title: '',
            img: '',
            description: '',
            price: '',
            timeToCook: '',
            weight: '',
            category: null,
            isSpecial: ''

        },
        status: 0
    }

    async componentDidMount() {

        await this.getCategories();
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            await this.getProduct();
        }

    }

    uploadedFile = (path) => {
        this.setState({product: {...this.state.product, img: path}})
    }
    getProduct = async () => {
        let data = [];
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');

        await axios.get(window.admin_base_URL + '/product/read/' + myParam)
            .then(res => {
                data = res.data.result
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return {product: data};
        });
    }
    getCategories = async () => {
        let data = [];
        await axios.get(window.admin_base_URL + '/category/list').then(res => {
            data = res.data.result
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return {categories: data};
        });
    };
    handleChange = event => {
        this.setState({
            product: {
                ...this.state.product,
                [event.target.name]: isFinite(event.target.value) ? parseInt(event.target.value) : event.target.value
            }
        });
    };
    createProduct = async event => {
        event.preventDefault();

        const {product} = this.state;
        let URL = window.admin_base_URL + "/product/create"
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            URL = window.admin_base_URL + "/product/update/" + myParam
            await axios.patch(URL, product)
                .then(async () => {
                    await this.setState({
                        product, status: 200
                    });
                    window.location.href = "/adminPanel/products"
                })
                .catch(async err => {
                    await this.setState({
                        product, status: 400
                    });
                    console.error(err)
                })
        } else {
            await axios.post(URL, product)
                .then(async () => {

                    await this.setState({
                        product, status: 200
                    });
                    window.location.href = "/adminPanel/products"
                })
                .catch(async err => {
                    await this.setState({
                        product, status: 400
                    });
                    console.error(err)
                })
        }

    };

    render() {

        const {
            title,
            price,
            timeToCook,
            weight,
            img,
            description,
            isSpecial,
            enabled,
            category
        } = this.state.product;
        const {status} = this.state;
        const categories = this.state.categories
        return (
            <div id='AdminProductForm'>
                <BannderHead title={"فرم ایجاد/ویرایش محصول"}/>
                <form className="ps-form--adminPanel" onSubmit={this.createProduct}>
                    <div className="row" dir='rtl'>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm "
                                    placeholder='نام محصول'
                                    type="text"
                                    name='title'
                                    required
                                    value={title || ""}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">

                                <input
                                    className="form-control shadow-sm no-spinners"
                                    type="number"
                                    placeholder='قیمت'
                                    required
                                    name='price'
                                    value={price}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">

                                <input
                                    className="form-control shadow-sm no-spinners"
                                    type="number"
                                    placeholder="زمان پخت"
                                    name='timeToCook'
                                    value={timeToCook}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">

                                <input
                                    className="form-control shadow-sm no-spinners"
                                    placeholder='وزن'
                                    type="number"
                                    name='weight'
                                    value={weight}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <select className=" form-control shadow-sm" value={category}
                                        name='category' onChange={this.handleChange}>
                                    <option value='' disabled>انتخاب دسته بندی</option>
                                    {categories.map(item => {
                                        return <option value={item._id}
                                                       key={item._id}>{item.title}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <FileUpload onUploadFile={this.uploadedFile} value={img} name='img'/>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input
                                    className="form-control shadow-sm"
                                    type="text"
                                    placeholder='توضیحات'
                                    name='description'
                                    value={description}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <select className=" form-control shadow-sm" value={enabled}
                                        name='enabled'
                                        onChange={this.handleChange}>
                                    <option value="" disabled>وضعیت موجودی</option>
                                    <option value={true}>موجود</option>
                                    <option value={false}>ناموجود</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <div className="form-group">
                                <select className=" form-control shadow-sm" value={isSpecial}
                                        name='isSpecial'
                                        onChange={this.handleChange}>
                                    <option value="" disabled>انتخاب نوع نمایش</option>
                                    <option value={true}>متمایز</option>
                                    <option value={false}>معمولی</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {status === 200 ?
                        <div className="alert alert-success text-right" role="alert">
                            درخواست با موفقیت ثبت شد
                        </div> : ''
                    }
                    {status === 400 ?
                        <div className="alert alert-danger text-right" role="alert">
                            خطا در ثبت اطلاعات رخ داده است.
                        </div> : ''
                    }

                    <button className="ps-btn  ps-btn--sm mr-auto ml-40" type="submit">ثبت
                        مشخصات
                    </button>
                </form>
            </div>
        );
    }
}
