import React, {Component} from "react";
import AdminLoginForm from "../../components/AdminLoginForm";
import BannderHead from "../../components/layout/BannderHead";

export default class AdminLoginPage extends Component {
    componentDidMount() {

    }


    render() {
        return (
            <div className="ps-page--profile mb-100">
                <BannderHead title={'ورود مدیر سایت '}/>
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                            <AdminLoginForm/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
