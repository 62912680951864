import React, { Component } from "react";
import $ from "jquery";



export default class BannderHead extends Component {
    constructor(props) {
        super(props);
        let isLoggedIn = false;
        this.state = {
            isLoggedIn
        };
    }

    componentDidMount() {
        var databackground = $('[data-background]');
        databackground.each(function () {
            if ($(this).attr('data-background')) {
                var image_path = $(this).attr('data-background');
                $(this).css({
                    'background-image': 'url(' + image_path + ')'
                });
            }
        });
    }

    render() {
        
        return (
            <div className={'ps-hero ps-hero--shopping '+ this.props.className} data-background="/img/page-header.jpg" style={{zIndex:'0'}}>
                <div className="ps-hero__container">
                    <h1 className="ps-hero__heading">{this.props.title || "لیست"}</h1>
                </div>
            </div>
        );
    }
}
