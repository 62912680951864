import React, {Component} from "react";
import * as leaflet from "./leaflet";


export default class MapInput extends Component {
    state = {
        location: []
    }

    constructor(props) {
        super(props);
        this.state.location = props.location;
    }

    componentDidMount() {

        let map = leaflet.map('map').setView(this.props.location, 13)
        leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);
        let icon = leaflet.icon({
            iconUrl: window.site_URL + 'img/map-marker-512.webp',
            iconSize: [40, 40], // size of the icon
        });
        let marker
        if (this.props.dragable){
            marker = new leaflet.marker(this.props.location, {draggable: 'true', icon}).addTo(map);
            const self = this;
            marker.on('dragend', function (event) {
                marker = event.target;
                let position = marker.getLatLng();
                marker.setLatLng(new leaflet.LatLng(position.lat, position.lng));
                self.changeLocation([position.lat, position.lng]);
                map.panTo(new leaflet.LatLng(position.lat, position.lng));
            });
        }
        else {
            marker = new leaflet.marker(this.props.location, {icon}).addTo(map);
        }

    }

    changeLocation = (path) => {
        this.props.onChangeLocation(path);
    }


    render() {
        this.changeLocation(this.state.marker)
        return (
            <div>
                <div id="map"/>
            </div>
        );
    }
}
