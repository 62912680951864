import React, { Component } from "react";
import IntroBanner from "../components/homePage/IntroBanner";
import SocialBanner from "../components/homePage/SocialBanner";
import InfoBanner from "../components/homePage/InfoBanner";
import LastBlogs from "../components/homePage/LastBlogs";
import SpecialProducts from "../components/homePage/SpecialProducts";


export default class HomePage extends Component {

    render() {
        return (
            <div id="homepage">
                <IntroBanner />
                <SpecialProducts />
                {/*<Products/>*/}
                <LastBlogs />
                <div className="ps-section ps-home-our-awards">
                    <SocialBanner instagram={this.props.instagram} />
                    <InfoBanner />
                </div>
            </div>
        );
    }
}
