import React, {Component} from "react";
import axios from "axios";
import BannderHead from "../../components/layout/BannderHead";
import FileUpload from "../../components/FileUpload";


export default class AdminSliderFormPage extends Component {

    state = {
        slider: {
            enabled: '',
            title: '',
            img: '',
            link: '',
            textLink: '',

        },
    }
    async componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            await this.getSlider();
        }
    }
    getSlider = async () => {
        let data = [];
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');

        await axios.get(window.admin_base_URL + '/slider/read/' + myParam)
            .then(res => {
                data = res.data.result
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return {slider: data};
        });
    }
    uploadedFile = (path) => {
        this.setState({slider: {...this.state.slider, img: path}})
    }
    handleChange = event => {
        this.setState({
            slider: {
                ...this.state.slider,
                [event.target.name]: event.target.value
            }
        });
    };
    createSlide = async event => {
        event.preventDefault();

        const {slider} = this.state;
        let URL = window.admin_base_URL + "/slider/create"
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            URL = window.admin_base_URL + "/slider/update/" + myParam
            await axios.patch(URL, slider)
                .then(async () => {
                    await this.setState({
                        slider, status: 200
                    });
                    window.location.href = "/adminPanel/slider"
                })
                .catch(async err => {
                    await this.setState({
                        slider, status: 400
                    });
                    console.error(err)
                })
        } else {
            await axios.post(window.admin_base_URL + "/slider/create", slider)
                .then(() => {

                    this.setState({
                        slider, status: 200
                    });
                    window.location.href = "/adminPanel/slider"

                })
                .catch(async err => {
                    await this.setState({
                        slider, status: 400
                    });
                    console.error(err)
                })
        }

    };

    render() {

        const {
            enabled,
            title,
            img,
            link,
            textLink
        } = this.state.slider;
        const {status} = this.state;
        return (
            <div id='AdminProductForm'>
                <BannderHead title={"فرم ایجاد/ویرایش بنر اختصاصی"}/>
                <div className="">
                    <form className="ps-form--adminPanel" onSubmit={this.createSlide}>
                        <div className="row justify-content-center" dir='rtl'>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                                <div className="form-group">

                                    <input
                                        className="form-control shadow-sm "
                                        placeholder='عنوان اسلایدر'
                                        type="text"
                                        name='title'
                                        value={title}
                                        required
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">

                                    <input
                                        className="form-control shadow-sm"
                                        type="text"
                                        placeholder='لینک'
                                        name='link'
                                        value={link}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">

                                    <input
                                        className="form-control shadow-sm"
                                        type="text" placeholder="متن لینک"
                                        name='textLink'
                                        value={textLink}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <FileUpload onUploadFile={this.uploadedFile} value={img} name='img'/>
                                </div>
                                <div className="form-group">
                                    <select className=" form-control shadow-sm" value={enabled}
                                            name='enabled'
                                            onChange={this.handleChange}>
                                        <option value="" disabled>وضعیت </option>
                                        <option value={true}>فعال</option>
                                        <option value={false}>غیرفعال</option>
                                    </select>
                                </div>
                                {status === 200 ?
                                    <div className="alert alert-success text-right" role="alert">
                                        درخواست با موفقیت ثبت شد
                                    </div> : ''
                                }
                                {status === 400 ?
                                    <div className="alert alert-danger text-right" role="alert">
                                        خطا در ثبت اطلاعات رخ داده است.
                                    </div> : ''
                                }
                                <div className="form-group ">

                                    <button className="ps-btn  ps-btn--sm mr-auto ml-40" type="submit">ثبت
                                        مشخصات
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        );
    }
}
