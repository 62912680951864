import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";


class LoginForm extends React.Component {
    constructor() {
        super();

        this.state = {
            phone: "",
            password: "",
            isLoggedIn: false,
            checkPhone: false,
            error: null,
            passwordSent: false

        };
    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    submitPhone = async event => {
        event.preventDefault();

        const { phone } = this.state;
        await axios.post(window.base_URL + "/login", { phone })
            .then(() => {
                this.setState({
                    phone,
                    checkPhone: true
                });
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    this.setState({
                        error: err.response, passwordSent: false
                    });
                }
            })
    };
    submitPassword = async event => {
        event.preventDefault();
        this.verifyLoading = true
        const { phone, password } = this.state;
        await axios.post(window.base_URL + "/verify", { phone, password })
            .then(res => {
                sessionStorage.setItem('client', JSON.stringify(res.data.result.client));
                sessionStorage.setItem('token', JSON.stringify(res.data.result.token));
                var now = new Date();
                var time = now.getTime();
                var expireTime = time + (30 * 24 * 60 * 60 * 1000);
                now.setTime(expireTime);
                document.cookie = "token=" + res.data.result.token + ';expires=' + now.toUTCString() + ';path=/';
                axios.defaults.headers.common['x-auth-token'] = sessionStorage.getItem('token');

                window.location.href = "profile"
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    this.setState({
                        error: err.response, passwordSent: false
                    });
                }
            })
    };

    render() {

        if (this.state.isLoggedIn) {
            return <Redirect to="/" />;
        }
        const { phone, password, error, passwordSent } = this.state;
        return (
            <div className='login-form card'>
                <form className='card-body text-right p-5'
                    onSubmit={this.state.checkPhone ? this.submitPassword : this.submitPhone} dir='rtl'>
                    <div hidden={this.state.checkPhone} className="form-group">
                        <label htmlFor="phone">شماره تلفن</label>
                        <input
                            dir='ltr'
                            name='phone'
                            className="form-control rounded-pill no-spinners"
                            type="number"
                            value={phone}
                            onChange={this.handleChange}
                            placeholder="09..."


                        />
                    </div>
                    <div hidden={!this.state.checkPhone} className="form-group">
                        <label htmlFor="password">رمز عبور ارسالی از طریق پیامک</label>
                        <input
                            dir='ltr'
                            className="form-control rounded-pill"
                            name='password'
                            type="password"
                            value={password}
                            placeholder="رمز عبور"
                            onChange={this.handleChange}
                        />
                    </div>
                    {error ?
                        <div className="alert alert-danger text-right" role="alert">{error.data.message}</div> : ''
                    }
                    {passwordSent ?
                        <div className="alert alert-success text-right" role="alert">رمز عبور برای شما از طریق پیامک ارسال شد.</div> : ''
                    }
                    <button type="submit" className='ps-btn'>{this.state.checkPhone ? "ورود" : "ارسال رمز"}</button>
                </form>
            </div>
        );
    }


}

export default LoginForm;
