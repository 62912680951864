import React, { Component } from "react";
import BannderHead from "../../components/layout/BannderHead";
import axios from "axios";
import Pagination from "../../components/Pagination";
import { groupBy } from "lodash";



export default class AdminClientOrderHistoryListPage extends Component {
    state = {
        orders: [],
        pagination: {},
        translate: {
            fa: {
                fast: "ارسال فوری",
                inPerson: "تحویل حضوری",
                normal: "ارسال عادی"
            }

        }
    }

    constructor() {
        super();
        this.state.isShow = 'daily';
    }

    async componentDidMount() {

        await this.getOrders();

    }

    getOrders = async () => {
        const { id } = this.props.match.params;
        let URL = window.admin_base_URL + '/client/' + id + '/orders'
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        if (page) URL = `${window.admin_base_URL}/client/${id}/orders?page=${page}`;
        let pagination = {};
        let data = [];
        await axios.get(URL)
            .then(res => {
                data = res.data.result
                pagination = res.data.pagination

                data.forEach(order => {
                    let total = 0;
                    order.products.forEach(item => {
                        total += parseInt(item.price)
                    })
                    order.totalPrice = total
                    order.products = order.products ? groupBy(order.products, "_id") : []
                })
            }).catch(err => {
                console.error(err)
            })
        this.setState(() => {
            return { orders: data, pagination: pagination };
        });
    }
    handleTarget = async event => {
        let target = event.target;
        this.setState(() => {
            return { isShow: target.value };
        });
    }

    render() {


        let { orders, pagination } = this.state;

        return (
            <div className="ps-page pb-75">
                <BannderHead title={'سوابق سفارشات مشتری'} />
                <div className='ml-4 mb-4 d-flex'>
                    <div className="w-50 text-right">
                        <span>تعداد کل: {pagination.count}</span>&nbsp;;&nbsp;
                        <span>تعداد نمایش در صفحه : {pagination.limit}</span>&nbsp;;&nbsp;
                        <span>شماره صفحه : {pagination.page}</span>
                    </div>
                    <div className="w-50">
                        <Pagination pagination={pagination} />
                    </div>
                </div>
                <table className="table table-hover table-striped  text-right mb-4">
                    <thead>

                        <tr>
                            <th scope="col">تاریخ سفارش</th>
                            <th scope="col">مشخصات مشتری</th>
                            <th scope="col">جزئیات سفارش</th>
                            <th scope="col">مبلغ سفارش</th>
                            <th scope="col">نوع ارسال</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((item, index) => {
                            return <tr key={index}>
                                <td>{item.created}</td>
                                {item.client ? <td>
                                    <div>{item.client.name}</div>
                                    <div>{item.client.shopTitle}</div>
                                    <div>{item.client.phone}</div>
                                    <div>{item.client.address}</div>
                                    <div>{item.client.postCode}</div>
                                </td> : <td></td>}
                                <td>
                                    {Object.entries(item.products)
                                        .sort((a, b) => a[0] > b[0] ? 1 : -1)
                                        .map((item, index) => {
                                            return <div key={index}> {item[1].length} {item[1][0].title}{item[1][0].weight ? <span> .... {item[1][0].weight}&nbsp;گرم</span> : ''}</div>
                                        })}
                                </td>
                                <td>{item.totalPrice.toLocaleString()}</td>
                                <td>{this.state.translate.fa[item.howToSend]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <Pagination pagination={pagination} />
            </div>
        );
    }
}
