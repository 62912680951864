import React, { Component } from "react";
import $ from 'jquery'; // from node_modules
import 'owl.carousel/dist/assets/owl.carousel.css';
import axios from "axios";
window.jQuery = $;
let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;


export default class IntroBanner extends Component {
    state = {
        sliders: [],
    }
    getSliders = async () => {
        let data = [];
        await axios.get(window.base_URL + '/slider/list').then(res => {
            data = res.data.result
        }).catch(err => {
            console.error(err)
        })
        this.setState(() => {
            return { sliders: data };
        });
    };
    async componentDidMount() {
        await this.getSliders();
        var databackground = $('[data-background]');
        databackground.each(function () {
            if ($(this).attr('data-background')) {
                var image_path = $(this).attr('data-background');
                $(this).css({
                    'background-image': 'url(' + image_path + ')'
                });
            }
        });


        async function owlRunner() {
            function setAnimation(_elem, _InOut) {
                // Store all animationend event name in a string.
                // cf animate.css documentation
                var animationEndEvent = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

                _elem.each(function () {
                    var $elem = $(this);
                    var $animationType = 'animated ' + $elem.data('animation-' + _InOut);

                    $elem.addClass($animationType).one(animationEndEvent, function () {
                        $elem.removeClass($animationType); // remove animate.css Class at the end of the animations
                    });
                });
            }

            var target = $('.owl-slider');
            if (target.length > 0) {
                target.each(function () {
                    var el = $(this);

                    if (target.children('div, span, a, img, h1, h2, h3, h4, h5, h5').length >= 2) {
                        el.owlCarousel({
                            animateIn: '',
                            animateOut: '',
                            margin: 0,
                            autoplay: true,
                            autoplayTimeout: 8000,
                            autoplayHoverPause: true,
                            loop: true,
                            nav: true,
                            mouseDrag: false,
                            touchDrag: true,
                            autoplaySpeed: 1000,
                            navSpeed: 1000,
                            dotsSpeed: 1000,
                            dragEndSpeed: 1000,
                            navText: [<i className='icon-chevron-left'></i>, <i className='icon-chevron-right'></i>],
                            dots: true,
                            items: 1,

                            responsive: {
                                0: {
                                    items: 1
                                },
                                480: {
                                    items: 1
                                },
                                768: {
                                    items: 1
                                },
                                992: {
                                    items: 1
                                },
                                1200: {
                                    items: 1
                                },
                                1680: {
                                    items: 1
                                }
                            },
                            rtl: false
                        });
                        // Fired before current slide change
                        el.on('change.owl.carousel', function (event) {
                            var $currentItem = $('.owl-item', el).eq(event.item.index);
                            var $elemsToanim = $currentItem.find("[data-animation-out]");
                            setAnimation($elemsToanim, 'out');
                        });

                        // Fired after current slide has been changed
                        el.on('changed.owl.carousel', function (event) {
                            var $currentItem = $('.owl-item', el).eq(event.item.index);
                            var $elemsToanim = $currentItem.find("[data-animation-in]");
                            setAnimation($elemsToanim, 'in');
                        })
                    }

                });
            }
        }

        await owlRunner()

    }

    render() {
        let { sliders } = this.state
        return (
            <div className="ps-home-banner" data-background="/img/home-banner.jpg">

                <div className="ps-carousel--dots owl-slider owl-carousel owl-theme owl-loaded owl-text-select-on">
                    {sliders.map(i => {
                        return <div key={i._id} className="ps-product--banner reverse">
                            <div className="ps-product__thumbnail">
                                <a href={i.link}>
                                    <img src={window.file_URL + i.img} alt="" />
                                </a>
                            </div>
                            <div className="ps-product__content ">
                                <a className="ps-product__title mb-30" href={i.link}>
                                    {i.title}
                                </a>
                                {i.link ? <a className="ps-btn" href={i.link}>{i.textLink}</a> : ''}
                            </div>
                        </div>
                    })}
                </div>
            </div>


        );
    }
}
