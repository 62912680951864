import React, {Component} from "react";

export default class SocialBanner extends Component {

    render() {
        const {instagram} = this.props;
        return (
            <div className="ps-section__left bg--cover" data-background="/img/instagram-section.jpg">
                <div className="ps-section__instagram">
                    <figure>
                        <a className="ps-instagram" href={instagram}>ما را دنبال کنید<i
                            className="fa fa-instagram"></i></a>
                    </figure>
                </div>
            </div>
        );
    }
}

