import React, {Component} from "react";
import ProductCard from "../Product/ProductCard";
import axios from "axios";

export default class SpecialProducts extends Component {
    state = {
        products:[],
    }
    async componentDidMount() {

        await this.getProducts();
    }
    getProducts = async () => {
        let data = [];
        await axios.get(window.base_URL + '/product/special').then(res => {
            data = res.data.result
        }).catch(err=>{
            console.error(err)
        })
        this.setState(() => {
            return {products: data};
        });
    };
    render() {
        let {products} = this.state
        return (
            <div className="ps-section ps-home-product">
                <div className="container-fluid">
                    <div className="ps-section__header"><i className="chikery-tt4"></i>
                        <h3>محصولات ویژه</h3>
                    </div>
                    <div className="ps-section__content">
                        <div className="row">
                            {products.map(product => {
                                return <div key={product._id} className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 ">
                                    <ProductCard  product={product}/>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

